import React from 'react';
import { Tabs, withStyles } from '@material-ui/core';

export default withStyles({
	root: {
		borderBottom: '1px solid #DFE3ED'
	},
	indicator: {
		height: 1,
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		'& > div': {
			maxWidth: 40,
			width: '100%',
			backgroundColor: '#333333'
		}
	}
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
