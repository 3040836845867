import React, { useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Link, withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Box, Card, makeStyles } from '@material-ui/core';

import Setting from './Setting';
import Security from './Security';

import request from 'utils/request';

const useStyles = makeStyles(theme => ({
	navs: {
		minWidth: '160px',
		position: 'relative',
		'& a': {
			paddingBottom: '15px',
			textDecoration: 'none',
			color: '#3C3C3C'
		},
		'& a.current, & a:hover': {
			color: '#364FCC'
		}
	}
}));

const SettingWrap = props => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [ navs ] = useState([
		{ title: 'password', link: '/desktop/dashboard/setting/password', msgCount: 0 },
		{ title: 'lang', link: '/desktop/dashboard/setting/language', msgCount: 0 }
	]);

	const logout = () => {
		request.clearToken();
		request.clearAgreement();
	};

	return (
		<Box mt={4} display='flex' alignItems='start'>
			<Box className={classes.navs} mr={2} display='flex' flexDirection='column' alignItems='start'>
				{navs.map((item, index) => (
					<a className={window.location.pathname === item.link ? 'current' : ''} href={item.link} key={item.title}>
						{t(`desktop.setting.navs.${item.title}`)}
					</a>
				))}
				<a onClick={() => logout()} href={'/desktop'} style={{ cursor: 'pointer' }}>
					{t('desktop.setting.navs.logout')}
				</a>
			</Box>

			<Card style={{ flex: '1', maxWidth: '1500px' }}>
				<Box p={4}>
					<Router>
						<Switch>
							<Route exact path='/desktop/dashboard/setting'>
								<Redirect to='/desktop/dashboard/setting/password' />
							</Route>
							<Route path='/desktop/dashboard/setting/password'>
								<Security />
							</Route>
							<Route path='/desktop/dashboard/setting/language'>
								<Setting />
							</Route>
						</Switch>
					</Router>
				</Box>
			</Card>
		</Box>
	);
};

export default withTranslation()(withRouter(SettingWrap));
