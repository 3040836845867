import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';

import 'core-js/es/Map';
import 'core-js/es/set';
import 'babel-polyfill';

import './i18n';
import './index.css';
import App from './App';
import theme from './themes/default';
import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://74419810a0be4a1da44cbc506a643550@sentry.shinyeasy.com/3"});

ReactDOM.render(
	// <React.StrictMode>
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<App />
	</ThemeProvider>,
	// </React.StrictMode>
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
