import React from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import { DEFAULT_LANG } from 'utils/tools';

const Item = props => {
	const { i18n } = props;

	const getValue = () => {
		if (
			props.data.action === 'control' &&
			!isEmpty(props.data.status) &&
			!isEmpty(props.data.status[props.data.value]) &&
			!isEmpty(props.data.status[props.data.value][i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en'])
		) {
			return props.data.status[props.data.value][i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en'];
		}

		if (
			props.data.action === 'level' &&
			!isEmpty(props.data.levels) &&
			!isEmpty(props.data.levels[props.data.value]) &&
			!isEmpty(props.data.levels[props.data.value][i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en'])
		) {
			return props.data.levels[props.data.value][i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en'];
		}

		return props.data.value || '';
	};

	return (
		<Box
			onClick={() => {
				if (isEmpty(props.data)) return;

				if (props.itemCallback) {
					props.itemCallback(props.data.action, props.data, props.warehousePartName);
				}
			}}
			width='45%'
			p='15px'
			mb='18px'
			display='flex'
			flexDirection='column'
			borderRadius='10px'
			bgcolor='white'>
			<Box pb='0' display='flex' alignItems='flex-end'>
				<Box fontSize='20px'>{getValue()}</Box>

				<Box ml='5px' mb='2px' fontSize='14px'>
					{isEmpty(props.data.units) ? '' : props.data.units[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']}
				</Box>
			</Box>

			<Box pt='10px' display='flex' alignItems='center' justifyContent='space-between'>
				<img alt='map' src={`${process.env.PUBLIC_URL}/images/ic_${props.data.type}.png`} style={{ width: '40px', height: '40px' }} />

				<Box fontSize='12px' color='#999999' textAlign='right'>
					{isEmpty(props.data.names) ? '' : props.data.names[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']}
				</Box>
			</Box>
		</Box>
	);
};

export default withTranslation()(Item);
