import React, { useState } from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import * as tools from 'utils/tools';

import EnvHourChart from 'components/EnvHourChart';
import EnvDayChart from 'components/EnvDayChart';

import 'moment/locale/en-ca';
import 'moment/locale/zh-cn';

const Status = props => {
	const { t, i18n } = props;

	moment.locale(i18n.language);

	const areaName = tools.getParms(props.location, 'areaName') || '';
	const cabinName = tools.getParms(props.location, 'cabinName') || '';
	const model = tools.getParms(props.location, 'model') || '';
	const controlSn = tools.getParms(props.location, 'controlSn') || '';
	const collectionSnArray = tools.getParms(props.location, 'collectionSnArray') || '';
	const data = tools.getParms(props.location, 'data') || '';

	const [ dayDate, setDayDate ] = useState(new Date());

	return (
		<Box height='100vh' display='flex' flexDirection='column' bgcolor='white' position='relative'>
			<Box px='20px' pt='15px' display='flex' justifyContent='space-between' alignItems='center'>
				{cabinName} - {areaName}
			</Box>

			<Box px='20px' py='15px' display='flex' justifyContent='space-between' alignItems='center'>
				<Box display='flex' alignItems='center'>
					<img alt='map' src={`${process.env.PUBLIC_URL}/images/ic_${data.type}.png`} style={{ width: '40px', height: '40px' }} />
					<Box ml='10px' fontSize='12px'>
						<strong style={{ fontSize: '18px' }}>
							{isEmpty(data.names) ? '' : data.names[i18n.language === tools.DEFAULT_LANG ? 'zh-cn' : 'us-en']}
						</strong>
						{` ( ${isEmpty(data.units) ? '' : data.units[i18n.language === tools.DEFAULT_LANG ? 'zh-cn' : 'us-en']} )`}
					</Box>
				</Box>

				<Box fontSize='14px'>{moment().format('YYYY.MM.DD')}</Box>
			</Box>

			<Box p='15px'>
				{t('common.lastRecord')} ({moment().startOf('hour').format('HH:mm')})
			</Box>

			<EnvHourChart collectionSnArray={collectionSnArray} model={model} controlSn={controlSn} data={data} point='second' />

			<Box p='15px' display='flex' justifyContent='space-between' alignItems='center'>
				<Box>{t('common.daysRecord')}</Box>

				<Box>
					{console.log(tools.getLanguage === tools.DEFAULT_LANG)}
					<MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
						<DatePicker
							autoOk
							label={t('common.selectDate')}
							value={dayDate}
							onChange={setDayDate}
							style={{ width: '100px' }}
							okLabel={t('common.ok')}
							cancelLabel={t('common.cancel')}
						/>
					</MuiPickersUtilsProvider>
				</Box>
			</Box>

			<EnvDayChart dayDate={dayDate} collectionSnArray={collectionSnArray} model={model} controlSn={controlSn} data={data} point='hour' />
		</Box>
	);
};

export default withTranslation()(Status);
