import isEmpty from 'is-empty';
import React, { useState, useEffect, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import request from 'utils/request';
import UITabs from 'components/UITabs';
import UITab from 'components/UITab';
import CapsuleSwitch from 'components/CapsuleSwitch';
import LogsList from 'components/LogsList';
import AppContext from 'context/AppContext';

const History = props => {
	const [ appInfo ] = useContext(AppContext);

	const [ tabIndex, setTabIndex ] = useState(0);
	const [ cabins, setCabins ] = useState([]);

	const handleChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	useEffect(
		() => {
			fetchCabins(appInfo.currentCapsuleId);
		},
		[ appInfo.currentCapsuleId ]
	);

	useEffect(
		() => {
			if (appInfo.currentCabinIndex >= 0 && appInfo.currentCabinIndex < cabins.length) {
				setTabIndex(appInfo.currentCabinIndex);
			} else {
				setTabIndex(0);
			}
		},
		[ appInfo.currentCabinIndex, cabins ]
	);

	const fetchCabins = async capsuleId => {
		if (isEmpty(capsuleId)) return;

		const response = await request.post('business-medical/cabin-group/get-by-id', { id: capsuleId });

		if (response.data && response.data.result === 0 && !isEmpty(response.data.data) && !isEmpty(response.data.data.cabins)) {
			setCabins(response.data.data.cabins);
		}
	};

	return (
		<Box height='100%' display='flex' flexDirection='column'>
			{/* tabs */}
			<UITabs value={tabIndex} onChange={handleChange} variant='fullWidth'>
				{cabins.map((warehouse, index) => {
					return <UITab key={index} label={warehouse.name} />;
				})}
			</UITabs>
			{cabins.map((warehouse, index) => {
				return <LogsList key={index} id={warehouse.id} tabIndex={tabIndex} index={index} />;
			})}
			<CapsuleSwitch />
		</Box>
	);
};

export default withTranslation()(History);
