import React, { useState, useEffect } from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import { Box, List, Dialog, makeStyles } from '@material-ui/core';

import request from 'utils/request';

import { DEFAULT_LANG } from 'utils/tools';

const useStyles = makeStyles({
	root: {
		'& .MuiPaper-root.MuiDialog-paperWidthSm': {
			background: 'none',
			boxShadow: 'none'
		},
		'& .MuiBackdrop-root': {
			backgroundColor: 'rgba(0, 0, 0, 0.8)'
		}
	}
});

const EnvControlDialog = props => {
	const { i18n } = props;

	const classes = useStyles();

	const [ value, setValue ] = useState('');

	useEffect(
		() => {
			setValue(props.value);
		},
		[ props.value ]
	);

	const onClose = () => {
		if (props.onClose) props.onClose();
	};

	const controlDevice = async (actionType, actionValue) => {
		const res = await request.post('laolaoliu/api/v1/set-device', {
			sn: props.sn,
			param: [
				{
					type: actionType,
					value: actionValue
				}
			]
		});

		//TODO need to toast result
		if (res.data && res.data.bizCode === 0) {
			setValue(actionValue);
		}
	};

	return isEmpty(props.data) ? (
		''
	) : (
		<Dialog onClose={onClose} aria-labelledby='simple-dialog-title' open={props.open} className={classes.root}>
			<Box minWidth='60vw' textAlign='center' color='white' fontSize='18px'>
				{isEmpty(props.data.names) ? '' : props.data.names[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']}
			</Box>
			
			<List>
				{Object.entries(props.data.status).map(state => {
					return (
						<Box
							key={state[0]}
							onClick={() => {
								controlDevice(props.data.type, state[0]);
							}}
							mt='15px'
							minHeight='70px'
							display='flex'
							justifyContent='center'
							alignItems='center'
							fontSize='18px'
							borderRadius='20px'
							color={state[0] === value ? 'white' : '#888888'}
							bgcolor={state[0] === value ? '#72B8F9' : 'white'}>
							{!isEmpty(state[1]) && !isEmpty(state[1][i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']) ? (
								state[1][i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']
							) : (
								''
							)}
						</Box>
					);
				})}
			</List>
		</Dialog>
	);
};

// UIControlDialog.propTypes = {
// 	onClose: PropTypes.func.isRequired,
// 	open: PropTypes.bool.isRequired,
// 	selectedValue: PropTypes.string.isRequired
// };

export default withTranslation()(EnvControlDialog);
