import React from 'react';
import { withStyles, Typography, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
	root: {
		margin: 0,
		minWidth: '80vw',
		padding: theme.spacing(2),
		backgroundColor: '#F3F5FA'
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;

	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant='h6'>{children}</Typography>

			{onClose ? (
				<Box onClick={onClose} display='flex' p='8px' top='20px' right='20px' position='absolute' borderRadius='5px' bgcolor='white'>
					<CloseIcon style={{ width: '15px', height: '15px', color: '#C2C2C2' }} />
				</Box>
			) : null}
		</MuiDialogTitle>
	);
});

export default DialogTitle;
