import React, { useEffect, useState } from 'react';
import isEmpty from 'is-empty';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { XAxis, YAxis, ResponsiveContainer, BarChart, Bar, ReferenceLine } from 'recharts';

import request from 'utils/request';

const StatusChartItem = props => {
	const [ data, setData ] = useState([]);

	const fetchHisotryData = async () => {
		const response = await request.post('laolaoliu/api/v1/get-device-history', {
			recordSnList: props.collectionSnArray,
			queryParam: [ props.data.key ],
			gmtStart: props.dayDate ? moment(props.dayDate).startOf('day').toISOString() : moment().subtract(1, 'days').toISOString(),
			gmtEnd: props.dayDate ? moment(props.dayDate).endOf('day').toISOString() : moment().toISOString(),
			basePoint: props.point === 'hour' ? props.point : '',
			pageLimit: 10000,
			pageIndex: 1
		});

		if (response.data && response.data.bizCode === 0 && response.data.bizData && response.data.bizData.historyData) {
			response.data.bizData.historyData.forEach(item => {
				item.value = isEmpty(item[props.data.key]) ? 0 : parseInt(item[props.data.key], 10);
				item.time =
					props.point === 'hour'
						? moment.utc(item.gmtHappen).local().format('HH:mm')
						: moment.utc(item.gmtHappen).local().format('HH:mm:ss');
			});

			setData(response.data.bizData.historyData);
		}
	};

	useEffect(
		() => {
			fetchHisotryData();
		},
		[ props.dayDate ]
	);

	return (
		<Box flex='1' p='10px' display='flex' style={{ overflowX: 'auto', overflowY: 'hidden' }}>
			<ResponsiveContainer>
				<BarChart data={data} margin={{ left: -15, right: 25 }}>
					{props.data.type === 'differentialPressure' ? <ReferenceLine y={0} label={'0Pa'} stroke='red' /> : ''}
					<XAxis dataKey='time' />
					<YAxis dataKey='value' domain={props.data.type === 'differentialPressure' ? [ -12, 12 ] : [ 0, 'dataMax' ]} />
					<Bar dataKey='value' fill='#72B8F9' />} />
				</BarChart>
			</ResponsiveContainer>
		</Box>
	);
};

export default withTranslation()(StatusChartItem);
