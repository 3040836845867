import React, { useEffect, useState } from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Box, Switch, Checkbox, FormControlLabel, TablePagination, InputBase, IconButton, makeStyles, Select, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import request from 'utils/request';
import { getLanguage, DEFAULT_LANG } from 'utils/tools';

const useStyles = makeStyles(theme => ({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center'
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	divider: {
		height: 28,
		margin: 4
	}
}));

const Develop = ({ t }) => {
	const history = useHistory();

	const classes = useStyles();

	const columns = [
		{
			title: t('desktop.capsule.table.id'),
			field: 'id',
			editable: 'never'
		},
		{
			title: t('desktop.capsule.table.name'),
			field: 'name',
			editable: 'never'
		},
		{
			title: t('desktop.capsule.table.password'),
			field: 'password',
			editable: 'always'
		}
	];

	const [ capsules, setCapsules ] = useState([]);

	useEffect(() => {
		fetchCapsules();
	}, []);

	const fetchCapsules = async () => {
		const response = await request.post('/business-medical/cabin-group/get-pwd');

		if (response.data.result === 0 && !isEmpty(response.data.data)) {
			setCapsules(response.data.data);
		}
	};

	const updatePassword = (newData, _) =>
		new Promise((resolve, reject) => {
			if (isEmpty(newData) || isEmpty(newData.password) || newData.password.length > 6) return reject();

			request
				.post('/business-medical/cabin-group/change-pwd', {
					id: newData.id,
					password: newData.password
				})
				.then(res => {
					if (res.data.result !== 0) return reject();

					const tmpCapsules = [ ...capsules ];

					for (let index = 0; index < tmpCapsules.length; index++) {
						if (tmpCapsules[index] && tmpCapsules[index].id === newData.id) {
							tmpCapsules[index] = newData;
							break;
						}
					}

					setCapsules(tmpCapsules);

					resolve();
				})
				.catch(err => {
					reject();
				});
		});

	return (
		<Box mt={3} position='relative'>
			<MaterialTable
				title={t('desktop.capsule.title')}
				columns={columns}
				data={capsules}
				editable={{
					onRowUpdate: updatePassword
				}}
				options={{
					paging: false,
					search: false,
					sorting: false,
					actionsColumnIndex: -1,
					headerStyle: {
						textAlign: 'center'
					},
					cellStyle: {
						textAlign: 'center'
					}
				}}
				localization={
					getLanguage() === DEFAULT_LANG ? (
						{
							header: { actions: ' ' },
							body: {
								editTooltip: '编辑',
								emptyDataSourceMessage: '没有任何记录',
								editRow: {
									saveTooltip: '保存',
									cancelTooltip: '取消'
								}
							}
						}
					) : (
						{}
					)
				}
			/>
		</Box>
	);
};

export default withTranslation()(Develop);
