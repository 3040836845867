import React, { useContext, useState, Fragment } from 'react';
import isEmpty from 'is-empty';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { TextField, Button, Grid, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import request from 'utils/request';

const LoginForm = ({ t }) => {
	const history = useHistory();
	const [ phone, setPhone ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ phoneTip, setPhoneTip ] = useState(' ');
	const [ passwordTip, setPasswordTip ] = useState(' ');
	const [ dialogOpen, setDialogOpen ] = React.useState(false);

	const handleClickOpen = () => {
		setDialogOpen(true);
	};

	const handleAgree = async () => {
		const response = await request.post('/business-medical/user/agreement');

		if (response.data && response.data.result === 0) {
			request.setAgreement(1);
			window.location = '/desktop/dashboard';
		}

		setDialogOpen(false);
	};

	const clearState = () => {
		setPhoneTip(' ');
		setPasswordTip(' ');
	};

	const login = async () => {
		clearState();

		if (isEmpty(phone) || isNaN(phone)) {
			setPhoneTip(t('desktop.phoneError'));
			return;
		}

		if (isEmpty(password)) {
			setPasswordTip(t('desktop.passwordError'));
			return;
		}

		const response = await request.post('/business-medical/user/login', { name: phone, password: password });

		if (response.data && response.data.data && response.data.result === 0) {
			request.setToken(response.data.data.token);

			if (response.data.data.agreement === 1) {
				request.setAgreement(response.data.data.agreement);
				window.location = '/desktop/dashboard';
			} else {
				setDialogOpen(true);
			}
		} else if (response.data && response.data.message) {
			setPasswordTip(response.data.message);
		} else {
			setPasswordTip('404');
		}
	};

	const inputPhone = event => {
		setPhone(event.target.value);
	};

	const inputPassword = event => {
		setPassword(event.target.value);
	};

	return (
		<Fragment>
			<form noValidate autoComplete='off'>
				<Grid container direction='column'>
					<Box width={260} mt={2}>
						<TextField
							fullWidth
							value={phone}
							label={t('desktop.phone')}
							type='tel'
							margin='normal'
							variant='outlined'
							onChange={inputPhone.bind(this)}
							error={phoneTip && phoneTip !== ' '}
							helperText={phoneTip}
						/>
					</Box>

					<Box width={260} mt={2}>
						<TextField
							fullWidth
							value={password}
							label={t('desktop.password')}
							type='password'
							autoComplete='current-password'
							margin='normal'
							variant='outlined'
							onChange={inputPassword.bind(this)}
							error={passwordTip && passwordTip !== ' '}
							helperText={passwordTip}
						/>
					</Box>

					<Box mt={3} textAlign='right'>
						<Button onClick={login} variant='contained' color='primary'>
							{t('desktop.login')}
						</Button>
					</Box>
				</Grid>
			</form>

			<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>{t('desktop.dialog.title')}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description' style={{ whiteSpace: 'pre-line' }}>
						{t('desktop.dialog.content')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDialogOpen(false)} color='primary'>
						{t('desktop.dialog.disagree')}
					</Button>
					<Button onClick={handleAgree} color='primary' autoFocus>
						{t('desktop.dialog.agree')}
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

export default withTranslation()(LoginForm);
