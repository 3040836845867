import React from 'react';
import Cookies from 'js-cookie';
import { Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

function LangSwitch({ t, i18n }) {
	const CN = 'zh-CN';
	const EN = 'en';

	const switchLang = lang => {
		i18n.changeLanguage(lang);
		Cookies.set('accept-language', lang);
	};

	return (
		<React.Fragment>
			<Button m={1} color={i18n.language === CN ? 'primary' : 'default'} onClick={() => switchLang(CN)}>
				{t('lang.cn')}
			</Button>
			<Button m={1} color={i18n.language === EN ? 'primary' : 'default'} onClick={() => switchLang(EN)}>
				{t('lang.en')}
			</Button>
		</React.Fragment>
	);
}

export default withTranslation()(LangSwitch);
