import React from 'react';
import isEmpty from 'is-empty';
import { makeStyles } from '@material-ui/core';

const DEFAULT_COLOR = '#c2c2c2';
const DEFAULT_ARROW_WIDTH = 5;
const DEFAULT_TIP_X_PADDING = 10;

const Sytle = makeStyles({
	tip: {
		padding: `2px ${DEFAULT_TIP_X_PADDING}px`,
		fontSize: '11px',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderRadius: '8px',
		position: 'relative'
	},
	arrow: {
		position: 'absolute',
		marginLeft: `-${DEFAULT_ARROW_WIDTH}px`,
		borderWidth: `${DEFAULT_ARROW_WIDTH}px`,
		borderStyle: 'solid'
	}
});

const getArrowStyle = (bgColor, arrow) => {
	const style = {};

	if (isEmpty(arrow)) {
		arrow = 'bc';
	}

	if (arrow.indexOf('t') < 0 && arrow.indexOf('b') < 0) {
		arrow = `b${arrow}`;
	}

	if (arrow.indexOf('t') >= 0) {
		style.bottom = '100%';
		style.borderColor = `transparent transparent ${bgColor || DEFAULT_COLOR} transparent`;
	}

	if (arrow.indexOf('b') >= 0) {
		style.top = '100%';
		style.borderColor = `${bgColor || DEFAULT_COLOR} transparent transparent transparent`;
	}

	if (arrow.indexOf('l') >= 0) {
		style.left = `${DEFAULT_TIP_X_PADDING}px`;
	}

	if (arrow.indexOf('c') >= 0) {
		style.left = '50%';
	}

	if (arrow.indexOf('r') >= 0) {
		style.right = `${DEFAULT_TIP_X_PADDING - DEFAULT_ARROW_WIDTH}px`;
	}

	return style;
};

const UITip = props => {
	const classes = Sytle();

	return (
		<div
			className={classes.tip}
			style={{
				textAlign: 'center',
				color: props.color || (props.bgColor ? 'white' : DEFAULT_COLOR),
				borderColor: props.bgColor || DEFAULT_COLOR,
				backgroundColor: props.bgColor || 'white'
			}}>
			{props.children}

			<span className={classes.arrow} style={getArrowStyle(props.bgColor, props.arrow)} />
		</div>
	);
};

export default UITip;
