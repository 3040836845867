import React, { Fragment, useState, useEffect, useContext } from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import CachedIcon from '@material-ui/icons/Cached';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Box, Fab, Dialog } from '@material-ui/core';

import request from 'utils/request';

import AppContext from 'context/AppContext';

import UIDialogTitle from 'components/UIDialogTitle';

const CapsuleSwitch = props => {
	const { t } = props;

	const [ appInfo, setAppInfo ] = useContext(AppContext);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ capsules, setCapsules ] = useState([]);

	useEffect(() => {
		fetchCapsule();
	}, []);

	const fetchCapsule = async () => {
		const res = await request.post('business-medical/cabin-group/get-by-user-id');

		if (!isEmpty(res.data) && res.data.result === 0 && !isEmpty(res.data.data)) {
			if (isEmpty(appInfo.currentCapsuleId)) {
				switchCapsule(res.data.data[0]);
			}

			setCapsules(res.data.data);
		}
	};

	const onOpen = () => {
		setIsOpen(true);
	};

	const onClose = () => {
		setIsOpen(false);
	};

	const switchCapsule = capsule => {
		if (isEmpty(capsule) || isEmpty(capsule.id)) return;

		setAppInfo(appInfo => ({
			...appInfo,
			currentCapsuleId: capsule.id
		}));

		onClose();
	};

	return (
		<Fragment>
			<Box position='fixed' right={0} bottom='10%'>
				<Fab
					onClick={onOpen}
					variant='extended'
					aria-label='Switch'
					style={{
						height: '42px',
						color: 'white',
						fontSize: '12px',
						backgroundColor: '#72B8F9',
						borderTopRightRadius: '0',
						borderBottomRightRadius: '0',
						boxShadow: '0px 1px 3px -1px rgba(0,0,0,0.2), 0px 2px 6px 0px rgba(0,0,0,0.14), 0px 1px 9px 0px rgba(0,0,0,0.12)'
					}}>
					<CachedIcon />
					<span style={{ marginLeft: '5px', textTransform: 'none' }}>{t('common.switchWareGroup')}</span>
				</Fab>
			</Box>

			<Dialog onClose={onClose} open={isOpen} style={{ top: '-40px' }}>
				<UIDialogTitle id='customized-dialog-title' onClose={onClose}>
					{t('common.switchWareGroupTitle')}
				</UIDialogTitle>
				<MuiDialogContent style={{ backgroundColor: '#F3F5FA', height: '60vh' }}>
					{isEmpty(appInfo.currentCapsuleId) ? (
						<Box flex='1' display='flex' justifyContent='center' alignItems='center'>
							{t('common.isNoneCapsule')}
						</Box>
					) : (
						<Box pd='30px' display='grid' gridTemplateColumns='auto auto auto' gridGap='10px'>
							{capsules.map(item => {
								return (
									<Box
										key={item.id}
										onClick={() => switchCapsule(item)}
										p='10px'
										mb='10px'
										display='flex'
										flexDirection='column'
										alignItems='center'
										borderRadius='10px'
										textAlign='center'
										color={appInfo.currentCapsuleId === item.id ? 'white' : '#333333'}
										bgcolor={appInfo.currentCapsuleId === item.id ? '#73b6f6' : 'white'}>
										<img
											alt='map'
											src={`${process.env.PUBLIC_URL}/images/img_warehouse.png`}
											style={{ width: '40px', height: '40px' }}
										/>
										<Box fontSize='11px'>{item.name}</Box>
									</Box>
								);
							})}
						</Box>
					)}
				</MuiDialogContent>
			</Dialog>
		</Fragment>
	);
};

export default withTranslation()(CapsuleSwitch);
