import React, { useReducer } from 'react';
import { Typography, Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import LoginForm from 'components/desktop/LoginForm';
import LangSwitch from 'components/desktop/LangSwitch';

const Login = ({ t }) => {
	return (
		<Box minHeight='100vh' minWidth='1008' display='flex' justifyContent='center' alignItems='center'>
			<Box minHeight={550} display='flex' style={{ background: 'white' }} justifyContent='center' alignItems='stretch'>
				<Box width={600} display='flex' justifyContent='center' alignItems='center' style={{ background: '#3c44d2', color: 'white' }}>
					<h1>
						<p>{t('desktop.subTitle')}</p>
					</h1>
				</Box>
				<Box width={408} display='flex' flexDirection='column' justifyContent='center' alignItems='center' position='relative'>
					<Box display='flex' alignItems='center'>
						<img
							alt='map'
							src={`${process.env.PUBLIC_URL}/images/img_logo.png`}
							style={{ width: '40px', height: '40px', marginRight: '15px' }}
						/>
						<Typography align='center' variant='h5'>
							{t('desktop.title')}
						</Typography>
					</Box>
					<Box mt={3}>
						<LoginForm />
					</Box>
					<Box position='absolute' top={2} right={4} display='flex'>
						<LangSwitch />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default withTranslation()(Login);
