import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import Res from 'themes/res';

const useStyles = makeStyles(theme => ({
	icon: {
		width: '120px',
		display: 'flex',
		textDecoration: 'none',
		justifyContent: 'center',
		'&': {
			color: '#ACACAD'
		},
		'& path': {
			fill: '#ACACAD'
		},
		'& .current': {
			color: '#364FCC'
		},
		'& .current path': {
			fill: '#364FCC'
		}
	}
}));

const Sidebar = props => {
	const { t } = props;
	const classes = useStyles();

	const [ navs ] = useState([
		{ title: 'main', icon: <Res.Icon.Dashboard />, link: '/desktop/dashboard/main' },
		{ title: 'capsule', icon: <Res.Icon.Home />, link: '/desktop/dashboard/capsule' },
		{ title: 'lightId', icon: <Res.Icon.Id />, link: '/desktop/dashboard/lightId' },
		{ title: 'setting', icon: <Res.Icon.Setting />, link: '/desktop/dashboard/setting' }
	]);

	return (
		<Box bgcolor='white'>
			<Box mt={8} display='flex' flexDirection='column' alignItems='center'>
				{navs.map((item, index) => (
					<a className={classes.icon} href={item.link} key={item.title}>
						<Box
							p={4}
							display='flex'
							flexDirection='column'
							alignItems='center'
							className={window.location.pathname.indexOf(item.link) >= 0 ? 'current' : ''}>
							<Box display='flex' width='24px' height='24px' py={1} alignItems='center'>
								{item.icon}
							</Box>

							<Box>{t(`desktop.navs.${item.title}`)}</Box>
						</Box>
					</a>
				))}
			</Box>
		</Box>
	);
};

export default withTranslation()(withRouter(Sidebar));
