import React, { useEffect, useState } from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Box, Typography } from '@material-ui/core';
import Iframe from 'react-iframe';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';

import request from 'utils/request';

const Main = ({ t }) => {
	const columns = [
		{ title: t('app.appId'), field: 'key', editable: 'never' },
		{ title: t('app.name'), field: 'name', editable: 'always' },
		{ title: t('app.package'), field: 'package', editable: 'onAdd' },
		{ title: t('app.status'), field: 'status', editable: 'never' },
		{ title: t('app.description'), field: 'description', editable: 'always' }
	];

	const history = useHistory();

	const [ isInit, setIsInit ] = useState(true);
	const [ apps, setApps ] = useState([]);

	useEffect(() => {});

	return (
		<Box display='flex' justifyContent='center' p={4} style={{height:"100%"}}>
			<Box textAlign='center'>
				<Box position='relative' py='10px'>
					<Typography variant='h6'>{t('desktop.main.titleStatus')}</Typography>
					<Box position='absolute' top='5px' left='10px'>
						<IconButton
							aria-label='Back'
							onClick={() => {
								if (isEmpty(document.getElementById('statusFrame').contentWindow.history.state)) return;

								document.getElementById('statusFrame').contentWindow.history.back();
							}}>
							<ArrowBack />
						</IconButton>
					</Box>
				</Box>

				<Iframe url='/warehouse' width='450px' height="85%" id='statusFrame' className='iframeBox' />
			</Box>
			<Box textAlign='center'>
				<Box position='relative' py='10px'>
					<Typography variant='h6'>{t('desktop.main.titleLogs')}</Typography>
					<Box position='absolute' top='5px' left='10px'>
						<IconButton
							aria-label='Back'
							onClick={() => {
								if (isEmpty(document.getElementById('logFrame').contentWindow.history.state)) return;

								document.getElementById('logFrame').contentWindow.history.back();
							}}>
							<ArrowBack />
						</IconButton>
					</Box>
				</Box>
				<Iframe url='/warehouse?type=history' width='450px'  height="85%" id='logFrame' className='iframeBox' />
			</Box>
		</Box>
	);
};

export default withTranslation()(Main);
