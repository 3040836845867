import React, { useEffect, useState } from 'react';
import isEmpty from 'is-empty';
import Cookies from 'js-cookie';
import { Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import AppContext from 'context/AppContext';

import DesktopIndex from 'layouts/desktop';
import Capsule from 'layouts/capsule/Capsule';
import HistoryIndex from 'layouts/logs';
import StatusIndex from 'layouts/env';
import telList from 'layouts/admin/TelList';
import OfflineList from 'layouts/admin/OfflinePassword';

function App(props) {
	const { t, i18n } = props;

	const [ appInfo, setAppInfo ] = useState({
		currentCapsuleId: '',
		currentCabinIndex: 0,
		currentOrgId:""
	});

	useEffect(
		() => {
			setLanguage(Cookies.get('accept-language'));
		},
		[ Cookies.get('accept-language') ]
	);

	const setLanguage = language => {
		if (!isEmpty(language)) {
			i18n.changeLanguage(language);
		}
	};

	return (
		<Box style={{ height: '100vh' }}>
			<AppContext.Provider value={[ appInfo, setAppInfo ]}>
				<BrowserRouter>
					<Switch>
						<Route path='/' exact render={() => <Box>{t('common.welcome')}</Box>} />
						<Route path='/desktop' component={DesktopIndex} />
						<Route path='/warehouse/history' component={HistoryIndex} />
						<Route path='/warehouse/status' component={StatusIndex} />
						<Route path='/warehouse' component={Capsule} />
						<Route path='/telList' component={telList} />
						<Route path='/offlineList' component={OfflineList} />
					</Switch>
				</BrowserRouter>
			</AppContext.Provider>
		</Box>
	);
}

export default withTranslation()(App);
