import React, { useEffect, useState, Fragment ,useContext } from 'react';
import AppContext from 'context/AppContext';
import { useHistory } from 'react-router-dom';
import {Prompt} from 'react-router';
import isEmpty from 'is-empty';
import { DEFAULT_LANG, getLanguage } from 'utils/tools';
import { withTranslation } from 'react-i18next';
import { Box, Fab, Button ,IconButton ,Dialog ,makeStyles } from '@material-ui/core';
import UIDialogTitle from 'components/UIDialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CachedIcon from '@material-ui/icons/Cached';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import request from 'utils/request';
import TextField from '@material-ui/core/TextField';
import * as jsbridge from 'utils/jsbridge';
import compareVersion from "utils/formatVersion";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const style = makeStyles({
    noAdminBox:{
        background:"#f1f1f1",
        height:"100vh"
    },
    noAdmin:{
        position: "absolute",
        top: "30px",
        left:"50%",
        transform:"translateX(-50%)",
        padding:"15px 10px",
        whiteSpace: "nowrap",
        background: "#ffffff"
    },
    adminBox:{
        padding:'10px 15px'
    },
	saveBtn:{
        width:'100%',
        fontSize:'16px',
        backgroundColor: '#286EFF',
        marginBottom:'20px',
        color:"#ffffff",
        '&:active': {
            backgroundColor: '#286EFF',
        },
        '&:hover': {
            backgroundColor: '#286EFF',
        }
    },
	adminText:{
        color:"#286EFF",
        fontSize:'14px',
        marginBottom:"10px"
    },
    telItem:{
        display:"flex",
        justifyContent:"space-between",
        padding:"10px 0",
        borderBottom:"1px solid #ebedf0",
        marginBottom:"20px"
    },
    telBox:{
        width:"70%"
    },
    phone:{
        fontSize:"20px"
    },
    remark:{
        fontSize:"14px",
        color:"#999999"
    },
    editBox:{
        whiteSpace:"nowrap",
        width:"30%",
        textAlign:"right"
    },
    editBtn:{
        color:"#286EFF",
        marginRight:"-15px"
    },
    delBtn:{
        marginRight:"-15px"
    },
    flexCenter:{
        display:"flex",
        alignItems:"center"
    },
    inputLabel:{
    },
    dialogfoot:{
        position:"absolute",
        width:"100%",
        bottom:0,
        left:0,
        display:"flex",
        alignItems:"center",
    },
    dialogCancel:{
        width: "50%",
        height: "50px",
        lineHeight: "50px",
        fontSize: "16px",
        textAlign: "center",
        color: "#999999",
        background:"#ffffff",
        borderTop: "1px solid #F0F0F0",
        borderBottomLeftRadius:"4px",
        '&:active': {
            background: '#F0F0F0',
        },
    },
    dialogConfirm:{
        width: "50%",
        height: "50px",
        lineHeight: "50px",
        fontSize: "16px",
        textAlign: "center",
        color: "#ffffff",
        background:"#286EFF",
        borderTop: "1px solid #286EFF",
        borderBottomRightRadius:"4px",
        '&:active': {
            background: '#3079F7',
        },
    }
});

const TelList = props => {
    const { t } = props;
    const [ appInfo, setAppInfo ] = useContext(AppContext);
    const classes = style();
    const history = useHistory();
    const [area,setArea]=useState("");
    const [phone,setPhone]=useState("");
    const [remark,setRemark]=useState("");
    const [editRemark,setEditRemark]=useState("");
    const [phoneId,setPhoneId]=useState("");
    const [message,setMessage]=useState("");
    const [orgList,setOrgList]=useState([]);
    const [telList,setTelList]=useState([]);
    const [isAdmin,setIsAdmin]=useState(true);
    const [isOpenOrg,setIsOpenOrg]=useState(false);
    const [isOpenAdd,setIsOpenAdd]=useState(false);
    const [isOpenEdit,setIsOpenEdit]=useState(false);
    const [isOpenDel,setIsOpenDel]=useState(false);
    const [messageOpen,setMessageOpen]=useState(false);
    

    const setTitle = () => {
		document.title = getLanguage() === DEFAULT_LANG ? '管理员' : 'Admin';
	};

    setTitle();
    
    useEffect(()=>{
        getOrgList();
        jsbridge.getAppVersion(showAppVersion);
    },[]);

    useEffect(()=>{
        getPhones(appInfo.currentOrgId);
    },[appInfo.currentOrgId]);

    const navigationRightMoreButtonClicked=()=>{
        history.push({pathname: '/offlineList',state: {orgId:appInfo.currentOrgId}});
    };

    window.navigationRightMoreButtonClicked=navigationRightMoreButtonClicked;

    const showAppVersion = (version) => {
		const u = navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        const offlineTitle=getLanguage() === DEFAULT_LANG ? '离线密码' : 'Offline-Pwd';
        const upText=getLanguage() === DEFAULT_LANG ? '请升级APP版本' : 'Please upgrade APP';
        if (isiOS) {
            if(compareVersion(version,"3.2.2")){
                jsbridge.showNavigationRightMoreButton(true,offlineTitle);
            }else {
                jsbridge.showRightTop("[{url:'', text:'"+upText+"'}]", t('admin.newVersionNotice'));
            }
        } else {
            if(compareVersion(version,"3.1.7")){
                jsbridge.showNavigationRightMoreButton(true,offlineTitle);
            }else {
                jsbridge.showRightTop("[{url:'', text:'"+upText+"'}]", t('admin.newVersionNotice'));
            }
        }
    };

    const getOrgList = async () => {
		const res = await request.get('business-medical/user-auth/get-org');

		if (!isEmpty(res.data) && res.data.result === 0 && !isEmpty(res.data.data)) {
            setIsAdmin(true);
            setOrgList(res.data.data);
            if(appInfo.currentOrgId===""){
                setAppInfo(appInfo => ({
                    ...appInfo,
                    currentOrgId: res.data.data[0].id
                }));
            }
		}else{
            setIsAdmin(false);
        }
	};

    const getPhones = async (id) => {
        if (isEmpty(id)) return;

		const res = await request.get('business-medical/user-auth/get-auth?orgId='+id);

		if (!isEmpty(res.data) && res.data.result === 0 && !isEmpty(res.data.data)) {
            setTelList(res.data.data);
		}
    };

    const saveAddTel = async () => {
        if (isEmpty(area)) return;
        if (isEmpty(phone)) return;

        const res = await request.post('business-medical/user-auth/add-auth',{orgId:appInfo.currentOrgId,phone:'+'+area+'-'+phone,remark:remark});

		if (res.data.result === 0) {
            onCloseAdd();
            getPhones(appInfo.currentOrgId);
		}else{
            setMessage(res.data.message);
            setMessageOpen(true);
        }
    };

    const saveEditRemark = async () => {
        const res = await request.post('business-medical/user-auth/update-remark',{orgId:appInfo.currentOrgId,phone:phoneId,remark:editRemark});

		if (res.data.result === 0) {
            onCloseEdit();
            getPhones(appInfo.currentOrgId);
		}
    };

    const saveDel = async () => {
        const res = await request.post('business-medical/user-auth/delete-auth',{orgId:appInfo.currentOrgId,phone:phoneId});

		if (res.data.result === 0) {
            onCloseDel();
            getPhones(appInfo.currentOrgId);
		}
    };

    const handleClose = () => {
        setMessageOpen(false);
    };
    
    const onOpenOrg = () => {
		setIsOpenOrg(true);
	};

	const onCloseOrg = () => {
		setIsOpenOrg(false);
    };
    
    const onSelectOrg=(item)=>{
        setAppInfo(appInfo => ({
			...appInfo,
			currentOrgId: item.id
		}));
        onCloseOrg();
    };

    const onOpenAdd = () => {
        setIsOpenAdd(true);
        setPhone("");
        setRemark("");
	};

	const onCloseAdd = () => {
		setIsOpenAdd(false);
    };

    const inputCode=(e)=>{
        setArea(e.target.value)
    };

    const inputPhone=(e)=>{
        setPhone(e.target.value)
    };

    const inputRemark=(e)=>{
        setRemark(e.target.value)
    };

    const onOpenEdit = (item) => {
        setIsOpenEdit(true);
        setEditRemark(item.remark);
        setPhoneId(item.phone);
    };
    
    const inputEditRemark=(e)=>{
        setEditRemark(e.target.value)
    };

	const onCloseEdit = () => {
		setIsOpenEdit(false);
    };

    const onOpenDel = (phone) => {
        setIsOpenDel(true);
        setPhoneId(phone);
	};

	const onCloseDel = () => {
		setIsOpenDel(false);
    };

	return (
        <Fragment>
            {isAdmin ? (
                <div className={classes.adminBox}>
                    <Button variant="contained" disableElevation className={classes.saveBtn} onClick={onOpenAdd}>{t('admin.addBtn')}</Button>
                    <div className={classes.adminText}>{t("admin.text")}</div>
                    {
                        telList.map(item=>{
                            return(
                                <Box className={classes.telItem} key={item.userId}>
                                    <div className={classes.telBox}>
                                        <div className={classes.phone}>{item.phone.indexOf('+')===-1?'+86-'+item.phone:item.phone}</div>
                                        <div className={classes.remark}>{item.remark}</div>
                                    </div>
                                    <div className={classes.editBox}>
                                        <IconButton aria-label="edit" className={classes.editBtn} onClick={()=>onOpenEdit(item)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton aria-label="delete" color="secondary" className={classes.delBtn} onClick={()=>onOpenDel(item.phone)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </Box>
                            )
                        })
                    }

                    <Box position='fixed' right={0} bottom='10%'>
                        <Fab
                            onClick={onOpenOrg}
                            variant='extended'
                            aria-label='Switch'
                            style={{
                                height: '42px',
                                color: 'white',
                                fontSize: '12px',
                                backgroundColor: '#72B8F9',
                                borderTopRightRadius: '0',
                                borderBottomRightRadius: '0',
                                boxShadow: '0px 1px 3px -1px rgba(0,0,0,0.2), 0px 2px 6px 0px rgba(0,0,0,0.14), 0px 1px 9px 0px rgba(0,0,0,0.12)'
                            }}>
                            <CachedIcon />
                            <span style={{ marginLeft: '5px', textTransform: 'none' }}>{t('admin.switch')}</span>
                        </Fab>
                    </Box>

                    <Dialog onClose={onCloseOrg} open={isOpenOrg} style={{ top: '-40px' }}>
                        <UIDialogTitle id='customized-dialog-title' onClose={onCloseOrg}>
                            {t('admin.switch')}
                        </UIDialogTitle>
                        <MuiDialogContent style={{ backgroundColor: '#F3F5FA', height: '60vh' }}>
                            {
                                <Box pd='30px' display='grid' gridTemplateColumns='auto auto auto' gridGap='10px'>
                                    {orgList.map(item => {
                                        return (
                                            <Box
                                                key={item.id}
                                                onClick={() => onSelectOrg(item)}
                                                p='10px'
                                                mb='10px'
                                                display='flex'
                                                flexDirection='column'
                                                alignItems='center'
                                                borderRadius='10px'
                                                textAlign='center'
                                                color={appInfo.currentOrgId === item.id ? 'white' : '#333333'}
                                                bgcolor={appInfo.currentOrgId === item.id ? '#73b6f6' : 'white'}>
                                                <img
                                                    alt='map'
                                                    src={`${process.env.PUBLIC_URL}/images/img_org.png`}
                                                    style={{ width: '40px', height: '40px',marginBottom:"5px" }}
                                                />
                                                <Box fontSize='11px'>{item.name}</Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            }
                        </MuiDialogContent>
                    </Dialog>

                    <Dialog onClose={onCloseAdd} open={isOpenAdd} style={{ top: '-40px' }} disableBackdropClick>
                        <UIDialogTitle id='customized-dialog-title' onClose={onCloseAdd}>
                            {t('admin.addTitle')}
                        </UIDialogTitle>
                        <MuiDialogContent style={{ backgroundColor: '#F3F5FA', height: '180px' ,position:"relative" }}>
                            {
                                <div>
                                    <div className={classes.flexCenter}>
                                        <div style={{position:"relative",width:"35%"}}>
                                            <div style={{position:"absolute",top:"10px",left:"3px",zIndex:2}}>+</div>
                                            <TextField id="code" label={t('admin.code')} variant="outlined" type="number" onChange={inputCode} size="small"/>
                                        </div>
                                        <div style={{width:"5%",textAlign:"center"}}>-</div>
                                        <TextField label={t('admin.phonePlaceHolder')} variant="outlined" type="number" style={{width:"60%"}} onChange={inputPhone} size="small"/>
                                    </div>

                                    <div style={{marginTop:"17px"}}>
                                        <TextField label={t('admin.remark')} variant="outlined" style={{width:"100%"}} size="small" onChange={inputRemark}/>
                                    </div>
                                    
                                    <div className={classes.dialogfoot}>
                                        <div className={classes.dialogCancel} onClick={onCloseAdd}>{t('admin.cancel')}</div>
                                        <div className={classes.dialogConfirm} onClick={saveAddTel}>{t('admin.confirm')}</div>
                                    </div>
                                </div>
                            }
                        </MuiDialogContent>
                    </Dialog>

                    <Dialog onClose={onCloseEdit} open={isOpenEdit} style={{ top: '-40px' }} disableBackdropClick>
                        <UIDialogTitle id='customized-dialog-title' onClose={onCloseEdit}>
                            {t('admin.editTitle')}
                        </UIDialogTitle>
                        <MuiDialogContent style={{ backgroundColor: '#F3F5FA', height: '130px' ,position:"relative" }}>
                            {
                                <div>
                                    <TextField label={t('admin.remark')} variant="outlined" style={{width:"100%"}} size="small" value={editRemark} onChange={inputEditRemark}/>
                                    
                                    <div className={classes.dialogfoot}>
                                        <div className={classes.dialogCancel} onClick={onCloseEdit}>{t('admin.cancel')}</div>
                                        <div className={classes.dialogConfirm} onClick={saveEditRemark}>{t('admin.confirm')}</div>
                                    </div>
                                </div>
                            }
                        </MuiDialogContent>
                    </Dialog>

                    <Dialog onClose={onCloseDel} open={isOpenDel} style={{ top: '-40px' }} disableBackdropClick>
                        <UIDialogTitle id='customized-dialog-title' onClose={onCloseDel}>
                            {t('admin.dialogDefaultTitle')}
                        </UIDialogTitle>
                        <MuiDialogContent style={{ backgroundColor: '#F3F5FA', height: '110px' ,position:"relative" }}>
                            {
                                <div>
                                    <div>{t('admin.delConfirm')}</div>
                                    
                                    <div className={classes.dialogfoot}>
                                        <div className={classes.dialogCancel} onClick={onCloseDel}>{t('admin.cancel')}</div>
                                        <div className={classes.dialogConfirm} onClick={saveDel}>{t('admin.confirm')}</div>
                                    </div>
                                </div>
                            }
                        </MuiDialogContent>
                    </Dialog>

                </div>
            ) : (
                <div className={classes.noAdminBox}>
                    <div className={classes.noAdmin}>{t('admin.noAdminText')}</div>
                </div>
            )}

            <Snackbar
                anchorOrigin={{vertical:'top',horizontal:'center'}}
                open={messageOpen}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="error">
                    {message}
                </Alert>
            </Snackbar>

            <Prompt message={() => {
                jsbridge.showNavigationRightMoreButton(false,"");
                return true;
            }}></Prompt>
        </Fragment>
	);
};

export default withTranslation()(TelList);