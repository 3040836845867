import React, { useEffect, useState } from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import { getEnvData } from 'utils/tools';

import request from 'utils/request';

import CabinEnvPointItem from 'components/CabinEnvPointItem';
import CabinLogsDeviceItem from 'components/CabinLogsDeviceItem';

const CABIN_TYPE_START = 'start';
const CABIN_TYPE_MIDDLE = 'middle';
const CABIN_TYPE_END = 'end';

const fetchTime = 5;

const CabinItem = props => {
	const [ envData, setEnvData ] = useState([]);

	useEffect(
		() => {
			const interval = setInterval(() => {
				fetchEnvData(props.model, props.controlSn, props.collectionSnArray);
			}, 1000 * fetchTime);

			fetchEnvData(props.model, props.controlSn, props.collectionSnArray);

			return () => {
				clearInterval(interval);
			};
		},
		[ props.model, props.controlSn, props.collectionSnArray ]
	);

	const fetchEnvData = async (model, controlSn, collectionSnArray) => {
		const modeResponse = await request.get(`laolaoliu/static/${model}`);
		const dataResponse = await request.post('laolaoliu/api/v1/get-device-status', {
			recordModel: model,
			controlSn: controlSn,
			recordSnList: collectionSnArray
		});

		setEnvData(getEnvData(modeResponse, dataResponse));
	};

	return (
		<Box
			onClick={() => {
				if (props.callBack) {
					props.callBack(props.devices, props.currentIndex);
				}
			}}
			mt={props.cabinType === CABIN_TYPE_START ? '5px' : '0'}
			pt={props.cabinType === CABIN_TYPE_START ? '20px' : '0'}
			display='flex'
			position='relative'>
			<Box display='flex' position='absolute' top='-5px' left='0' alignItems='center'>
				{props.title}
				<img
					alt='status'
					src={props.status === 0 ? `${process.env.PUBLIC_URL}/images/ic_online.png` : `${process.env.PUBLIC_URL}/images/ic_offline.png`}
					style={{ width: '20px', height: '20px', marginLeft: '5px' }}
				/>
			</Box>

			<img alt='map' src={`${process.env.PUBLIC_URL}/images/img_warehouse_${props.cabinType}.png`} />

			{props.displayType === 'history' ? <CabinLogsDeviceItem cabinType={props.cabinType} devices={props.devices} /> : ''}

			{props.displayType === 'status' && !isEmpty(envData) ? <CabinEnvPointItem points={envData} /> : ''}
		</Box>
	);
};

export default withTranslation()(CabinItem);

export { CABIN_TYPE_START, CABIN_TYPE_MIDDLE, CABIN_TYPE_END };
