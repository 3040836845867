import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import LangSwitch from 'components/desktop/LangSwitch';

const Setting = ({ t }) => {
	return (
		<Box>
			<Box>
				<Typography variant='h5' gutterBottom>
					{t('desktop.switchLang')}
				</Typography>
				<Box display='flex' justifyContent='flex-end'>
					<LangSwitch />
				</Box>
			</Box>
		</Box>
	);
};

export default withTranslation()(Setting);
