import React from 'react';
import isEmpty from 'is-empty';
import { withRouter, Link } from 'react-router-dom';
import { Breadcrumbs, Typography, Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const Breadcrumb = props => {
	const t = props.t;
	const urlArray = props.location.pathname.split('/');

	const getUrl = (urlArray, currentIndex) => {
		let url = '/';

		urlArray.forEach((item, index) => {
			if (index > currentIndex || index === 0) {
				return;
			}

			url = url + item;

			if (index !== currentIndex) {
				url = url + '/';
			}
		});

		return url;
	};

	return (
		<Breadcrumbs maxItems={8} aria-label='breadcrumb'>
			{urlArray.map((item, index) => {
				if (isEmpty(item) || item === 'desktop') return;

				if (index === urlArray.length - 1) {
					return (
						<Box color='#777' key={item}>
							{t(`breadcrumb.${item}`)}
						</Box>
					);
				}

				return (
					<a href={getUrl(urlArray, index)} key={item} style={{ color: '#777', textDecoration: 'none' }}>
						{t(`breadcrumb.${item}`)}
					</a>
				);
			})}
		</Breadcrumbs>
	);
};

export default withTranslation()(withRouter(Breadcrumb));
