import React, { useState, useEffect } from 'react';
import isEmpty from 'is-empty';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { Box } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import * as tools from 'utils/tools';
import request from 'utils/request';

import LogsDetail from 'components/LogsDetail';

const pageSize = 25;

const Detail = props => {
	const { t } = props;

	const [ id ] = useState(tools.getParms(props.location, 'id'));
	const [ title ] = useState(tools.getParms(props.location, 'title'));
	const [ color ] = useState(tools.getParms(props.location, 'color'));
	const [ records, setRecords ] = useState([]);
	const [ hasMore, setHasMore ] = useState(false);

	const [ selectedDate, setSelectedDate ] = useState(moment().toISOString());

	const handleDateChange = date => {
		setSelectedDate(moment(date).endOf('day').toISOString());
	};

	useEffect(
		() => {
			fetchLogs(id, true);
		},
		[ selectedDate ]
	);

	const onLoadMore = page => {
		fetchLogs(id);
	};

	const fetchLogs = async (entranceId, isRefresh) => {
		if (isEmpty(entranceId)) return;

		setHasMore(false);

		const res = await request.post('business-medical/log/get-by-entrance-id', {
			id: entranceId,
			gmtCreated:
				!isRefresh && records && !isEmpty(records) && moment(records[records.length - 1])
					? moment(records[records.length - 1].gmtCreated).toISOString()
					: selectedDate,
			pageSize: pageSize
		});

		if (res.data && res.data.result === 0) {
			if (isRefresh) {
				setRecords(res.data.data);
			} else {
				let tempRecords = [ ...records ].concat(res.data.data);
				setRecords(tempRecords);
			}

			setHasMore(!isEmpty(res.data.data));
		} else {
			setHasMore(true);
		}
	};

	return (
		<Box height='100%' display='flex' flexDirection='column'>
			<Box position='absolute' right='10px' top='5px'>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<DatePicker
						autoOk
						label={t('common.selectDate')}
						format='yyyy-MM-dd'
						value={selectedDate}
						onChange={handleDateChange}
						style={{ width: '100px' }}
					/>
				</MuiPickersUtilsProvider>
			</Box>

			<LogsDetail
				line
				detail
				hasMore={hasMore}
				loadMore={onLoadMore}
				records={records}
				data={{
					id: id,
					name: title,
					color: color
				}}
			/>
		</Box>
	);
};

export default withTranslation()(Detail);
