import React, { Fragment } from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import UITip from 'components/UITip';

const CABIN_TYPE_END = 'end';

const CabinLogsDeviceItem = props => {
	const { cabinType, devices } = props;

	if (isEmpty(cabinType) || isEmpty(devices)) return;

	const deviceConfig = [
		{
			top: '86px',
			left: '16px',
			arrow: 'tl',
			bgColor: '#D2BDED'
		},
		{
			top: '66px',
			left: '130px',
			arrow: 'tc',
			bgColor: '#72B8F9'
		},
		{
			top: '85px',
			left: '215px',
			arrow: 'tr',
			bgColor: '#9DDEE8'
		},
		{
			top: '152px',
			left: '215px',
			arrow: 'tr',
			bgColor: '#D2D2D2'
		},
		{
			top: cabinType === CABIN_TYPE_END ? '106px' : '136px',
			left: '130px',
			arrow: 'bc',
			bgColor: '#72B8F9'
		}
	];

	return (
		<Fragment>
			{devices.map(deivce => {
				if (deivce.index < deviceConfig.length && !isEmpty(deviceConfig[deivce.index])) {
					return (
						<Box
							width='90px'
							key={deivce.index}
							position='absolute'
							top={deviceConfig[deivce.index].top}
							left={deviceConfig[deivce.index].left}>
							<UITip key={deivce.index} bgColor={deviceConfig[deivce.index].bgColor} arrow={deviceConfig[deivce.index].arrow}>
								{deivce.name}
							</UITip>
						</Box>
					);
				} else return '';
			})}
		</Fragment>
	);
};

export default withTranslation()(CabinLogsDeviceItem);
