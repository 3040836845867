import React, { useEffect, useState, useContext } from 'react';
import isEmpty from 'is-empty';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import request from 'utils/request';
import * as jsbridge from 'utils/jsbridge';

import AppContext from 'context/AppContext';

import UITabs from 'components/UITabs';
import UITab from 'components/UITab';
import EnvAreaList from 'components/EnvAreaList';
import EnvControlDialog from 'components/EnvControlDialog';
import CapsuleSwitch from 'components/CapsuleSwitch';

const Overview = () => {
	const history = useHistory();

	const [ appInfo, setAppInfo ] = useContext(AppContext);

	const [ tabIndex, setTabIndex ] = useState(0);
	const [ cabins, setCabins ] = useState([]);
	const [ isShowControl, setIsShowControl ] = React.useState(false);
	const [ controlData, setControlData ] = useState(null);

	useEffect(
		() => {
			fetchCabins(appInfo.currentCapsuleId);
			jsbridge.setWebUnscrollable();
		},
		[ appInfo.currentCapsuleId ]
	);

	useEffect(
		() => {
			if (appInfo.currentCabinIndex >= 0 && appInfo.currentCabinIndex < cabins.length) {
				setTabIndex(appInfo.currentCabinIndex);
			} else {
				setTabIndex(0);
			}
		},
		[ appInfo.currentCabinIndex, cabins ]
	);

	const onTabSwitch = (event, value) => {
		setTabIndex(value);
		setAppInfo(appInfo => ({
			...appInfo,
			currentCabinIndex: value
		}));
	};

	const onItemClick = (action, data, areaName) => {
		if (action === 'control') {
			setControlData(data);
			setIsShowControl(true);
		}

		if (action === 'chart') {
			history.push({
				pathname: '/warehouse/status/chart',
				state: {
					cabinName: cabins && cabins[tabIndex] && cabins[tabIndex].name,
					areaName: areaName,
					model: cabins && cabins[tabIndex] && cabins[tabIndex].collectorTemplateName ? cabins[tabIndex].collectorTemplateName : '',
					controlSn: cabins && cabins[tabIndex] && cabins[tabIndex].lightSn ? cabins[tabIndex].lightSn : '',
					collectionSnArray: cabins && cabins[tabIndex] && cabins[tabIndex].collectors ? cabins[tabIndex].collectors : '',
					data: data
				}
			});
		}
	};

	const onControlDialogClose = value => {
		setIsShowControl(false);
	};

	const fetchCabins = async capsuleId => {
		if (isEmpty(capsuleId)) return;

		const response = await request.post('business-medical/cabin-group/get-by-id', { id: capsuleId });

		if (response.data && response.data.result === 0 && !isEmpty(response.data.data) && !isEmpty(response.data.data.cabins)) {
			setCabins(response.data.data.cabins);
		}
	};

	return (
		<Box height='100%' display='flex' flexDirection='column' bgcolor='#f3f5fa'>
			<UITabs value={tabIndex} onChange={onTabSwitch} variant='fullWidth'>
				{cabins.map(warehouse => {
					return <UITab key={warehouse.index} label={warehouse.name} />;
				})}
			</UITabs>

			{cabins.map((cabin, index) => {
				return (
					<EnvAreaList
						key={index}
						id={cabin.id}
						model={cabin.collectorTemplateName}
						controlSn={cabin.lightSn}
						collectionSnArray={cabin.collectors}
						tabIndex={tabIndex}
						index={index}
						itemCallback={onItemClick}
					/>
				);
			})}

			<EnvControlDialog
				sn={cabins && cabins[tabIndex] && cabins[tabIndex].lightSn ? cabins[tabIndex].lightSn : ''}
				data={controlData}
				value={isEmpty(controlData) ? '' : controlData.value}
				open={isShowControl}
				onClose={onControlDialogClose}
			/>
			<CapsuleSwitch />
		</Box>
	);
};

export default withTranslation()(Overview);
