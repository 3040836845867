import React, { Fragment } from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import { DEFAULT_LANG, getPressure } from 'utils/tools';

import UITip from 'components/UITip';

const CabinEnvPointItem = props => {
	const { points, i18n } = props;

	const pointConfigs = [
		{
			top: '80px',
			left: '160px',
			arrow: 'bc',
			bgColor: '#72B8F9'
		},
		{
			top: '30px',
			left: '40px',
			arrow: 'bc',
			bgColor: '#D2BDED'
		},
		{
			top: '130px',
			left: '40px',
			arrow: 'bc',
			bgColor: '#9DDEE8'
		},
		{
			top: '80px',
			left: '0px',
			arrow: 'bc',
			bgColor: '#d2d2d2'
		},
		{
			top: '154px',
			left: '250px',
			arrow: 'tr',
			bgColor: '#D2D2D2'
		}
	];

	return (
		<Fragment>
			{points.map(point => {
				const pressure = getPressure(point.datas);

				if (!isEmpty(point) && pointConfigs.length > point.index && !isEmpty(pointConfigs[point.index]) && !isEmpty(pressure)) {
					if (point.index === pointConfigs.length - 1) {
						return (
							<Box key={point.index} position='absolute' top='-15px' right='10px' fontSize='11px'>
								<Box>
									<Box>
										{isEmpty(point.names) ? '' : point.names[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']}{' '}
									</Box>
									<Box>
										{isEmpty(pressure.names) ? '' : pressure.names[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']}:
										{pressure.value}
										{isEmpty(pressure.units) ? '' : pressure.units[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']}
									</Box>
								</Box>
							</Box>
						);
					} else {
						return (
							<Box key={point.index} position='absolute' top={pointConfigs[point.index].top} left={pointConfigs[point.index].left}>
								<UITip key={point.index} bgColor={pointConfigs[point.index].bgColor} arrow={pointConfigs[point.index].arrow}>
									<div>
										<Box fontSize='14px'>
											{isEmpty(point.names) ? '' : point.names[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']}
										</Box>
										<div>
											{isEmpty(pressure.names) ? (
												''
											) : (
												pressure.names[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']
											)}:
											{pressure.value}
											{isEmpty(pressure.units) ? (
												''
											) : (
												pressure.units[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']
											)}
										</div>
									</div>
								</UITip>
							</Box>
						);
					}
				} else return '';
			})}
		</Fragment>
	);
};

export default withTranslation()(CabinEnvPointItem);
