import React, { useState, useEffect, useContext } from 'react';
import isEmpty from 'is-empty';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import request from 'utils/request';
import { DEFAULT_LANG, getLanguage } from 'utils/tools';

import AppContext from 'context/AppContext';

import CapsuleSwitch from 'components/CapsuleSwitch';
import CabinItem, { CABIN_TYPE_START, CABIN_TYPE_MIDDLE, CABIN_TYPE_END } from 'components/CabinItem';

const Capsule = props => {
	const { t } = props;
	const history = useHistory();
	//TODO repalce this way to differentiate layout type
	const pageType = queryString.parse(props.location.search)['type'] || 'status';

	const [ appInfo, setAppInfo ] = useContext(AppContext);
	const [ cabins, setCabins ] = useState([]);
	const [ isNone, setIsNone ] = useState(false);

	const setTitle = () => {
		if (pageType === 'history') {
			document.title = getLanguage() === DEFAULT_LANG ? '操作记录' : 'Op Log';
		} else {
			document.title = getLanguage() === DEFAULT_LANG ? '火眼智慧舱' : 'Gene capsule';
		}
	};

	setTitle();

	useEffect(
		() => {
			const interval = setInterval(() => {
				fetchCabins(appInfo.currentCapsuleId);
			}, 1000 * 10);

			fetchCabins(appInfo.currentCapsuleId);

			return () => {
				clearInterval(interval);
			};
		},
		[ appInfo.currentCapsuleId ]
	);

	const fetchCabins = async capsuleId => {
		if (isEmpty(capsuleId)) return;

		const response = await request.post('business-medical/cabin-group/get-by-id', { id: capsuleId });

		if (response.data && response.data.result === 0 && !isEmpty(response.data.data) && !isEmpty(response.data.data.cabins)) {
			setCabins(response.data.data.cabins);
		} else {
			setIsNone(true);
		}
	};

	const onCabinClick = (item, currentIndex) => {
		setAppInfo(appInfo => ({
			...appInfo,
			currentCabinIndex: currentIndex
		}));

		history.push({ pathname: `/warehouse/${pageType}` });
	};

	return (
		<Box py={2} display='flex' alignItems='center' flexDirection='column' overflow='auto'>
			{/* {isNone || isEmpty(appInfo.currentCapsuleId) ? (
				<Box flex='1' display='flex' alignItems='center'>
					{t('common.isNone')}
				</Box>
			) : (
				
			)} */}
			{cabins.map((item, index) => {

				let cabinType = CABIN_TYPE_MIDDLE;

				if (item.index === 0) {
					cabinType = CABIN_TYPE_START;
				}

				if (item.index === cabins.length - 1) {
					cabinType = CABIN_TYPE_END;
				}

				return (
					<CabinItem
						key={item.id}
						title={item.name}
						status={item.status}
						displayType={pageType}
						cabinType={cabinType}
						devices={item.entrances}
						model={item.collectorTemplateName}
						controlSn={item.lightSn}
						collectionSnArray={item.collectors}
						currentIndex={index}
						callBack={onCabinClick}
					/>
				);
			})}

			<CapsuleSwitch />
		</Box>
	);
};

export default withTranslation()(Capsule);
