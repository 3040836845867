import React, { useState ,useEffect, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import * as tools from 'utils/tools';
import { DEFAULT_LANG, getLanguage } from 'utils/tools';
import isEmpty from 'is-empty';
import request from 'utils/request';
import { Box, makeStyles } from '@material-ui/core';

const style = makeStyles({
    adminBox:{
        padding:'15px'
    },
    noticeBox:{
        background: "#f1f1f1",
        color: "#999999",
        fontSize: "16px",
        padding: "10px 15px",
        border: "1px solid #999999",
        borderRadius: "10px",
        lineHeight: "30px",
        marginBottom: "10px"
    },
    offlineItem:{
        display:"flex",
        alignItems:"center",
        justifyContent: "space-between",
        padding: "10px 0",
        fontSize:"16px"
    }
});

const OfflineList = props => {
    const { t} = props;

    const [orgId]  = useState(tools.getParms(props.location, 'orgId'));
    //1BJMCLKFOV550H6H3EV3K7HL6A

    const setTitle = () => {
		document.title = getLanguage() === DEFAULT_LANG ? '离线密码' : 'Offline-Pwd';
    };
    setTitle();

    const classes=style();
    const [offlineList,setOfflineList]=useState([]);
    
    useEffect(()=>{
        getOfflineList();
    },[]);

    const getOfflineList = async () => {
		const res = await request.get('business-medical/user-auth/get-cabin-pwd?orgId='+orgId);

		if (!isEmpty(res.data) && res.data.result === 0 && !isEmpty(res.data.data)) {
            setOfflineList(res.data.data)
		}
    };
    
    return(
        <Fragment>
            <div className={classes.adminBox}>
                <div className={classes.noticeBox}>{t("admin.offlineText")}</div>
                {
                    offlineList.map(item=>{
                        return(
                            <Box className={classes.offlineItem} key={item.id}>
                                <div>{item.name}</div>
                                <div style={{color:"#286EFF",fontWeight:"bold"}}>{item.password&&item.password!=null?item.password+'#':'-'}</div>
                            </Box>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}

export default withTranslation()(OfflineList);