import React, { Fragment } from 'react';
import isEmpty from 'is-empty';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const SIZE_TITLE = 14;
const SPACE_BORDER = '20px';
const COLOR_GRAY = '#999999';
const DEFAULT_COLOR = '#72B8F9';

const colorConfig = [ '#D2BDED', '#72B8F9', '#9DDEE8', '#D2D2D2', '#72B8F9' ];

const SubItem = props => {
	return (
		<Box px={SPACE_BORDER} py={1} display='flex' justifyContent='space-between' alignItems='center' position='relative' fontSize={12}>
			<Box display='flex'>
				<Box color={COLOR_GRAY}>{isEmpty(props.data.gmtCreated) ? '' : moment(props.data.gmtCreated).local().format('MM-DD HH:mm:ss')}</Box>
			</Box>

			<Box
				minWidth='80px'
				ml='-40px'
				position='absolute'
				left='50%'
				textAlign='center'
				color={props.data.portType === 1 ? '#EE8C46' : '#2D7CFF'}>
				{props.data.typeName}
			</Box>

			<Box textAlign='right'>{props.data.userName}</Box>
		</Box>
	);
};

const Item = props => {
	const history = useHistory();

	return (
		<Fragment>
			<Box
				onClick={() => {
					if (props.detail || isEmpty(props.data.id) || isEmpty(props.data.name)) return;

					history.push({
						pathname: '/warehouse/history/detail',
						state: {
							id: props.data.id,
							title: props.data.name,
							color: props.data.index < colorConfig.length ? colorConfig[props.data.index] : DEFAULT_COLOR
						}
					});
				}}
				mt={2}
				mb={props.detail ? 1 : 0}
				pb={props.detail ? 2 : 0}
				display='flex'
				alignItems='center'
				borderBottom={props.detail ? '1px solid #F3F4F5' : ''}>
				<Box
					width='5px'
					height='12px'
					mr='15px'
					bgcolor={props.data.index < colorConfig.length ? colorConfig[props.data.index] : DEFAULT_COLOR}
				/>

				<Box flex='1' fontSize={SIZE_TITLE}>
					{props.data.name}
				</Box>

				<Box mr={SPACE_BORDER}>{props.detail ? '' : <ArrowForwardIosIcon style={{ color: COLOR_GRAY, fontSize: SIZE_TITLE }} />}</Box>
			</Box>

			{props.data.list.map((record, index) => {
				return <SubItem key={index} data={record} />;
			})}
		</Fragment>
	);
};

export default withTranslation()(Item);
