import isEmpty from 'is-empty';
import Cookies from 'js-cookie';

const DEFAULT_LANG = 'zh-CN';

const getLanguage = () => {
	let language = Cookies.get('accept-language');

	if (isEmpty(language)) {
		language = localStorage.getItem('i18nextLng');
	}

	if (isEmpty(language)) {
		language = window.navigator.language === 'zh-Hans-US' ? 'zh-CN' : window.navigator.language;
	}

	return language;
};

const getParms = (location, key) => {
	if (location.state && location.state[key]) {
		localStorage.setItem(key, JSON.stringify(location.state[key]));

		return location.state[key];
	}

	if (localStorage.getItem(key)) {
		return JSON.parse(localStorage.getItem(key));
	}

	return null;
};

const getTypeDesc = (type, ocean) => {
	for (let index = 0; index < ocean.length; index++) {
		if (!isEmpty(ocean[index][type])) {
			return ocean[index][type];
		}
	}
};

const getPressure = dataList => {
	let reslute = null;

	for (let index = 0; index < dataList.length; index++) {
		if (dataList[index].type === 'pressure') {
			reslute = dataList[index];
			break;
		}

		if (dataList[index].type === 'differentialPressure') {
			reslute = dataList[index];
			break;
		}
	}

	return reslute;
};

const getEnvData = (modelRes, dataRes) => {
	if (isEmpty(modelRes) || isEmpty(modelRes.data) || isEmpty(modelRes.data.areaList)) return [];
	if (isEmpty(dataRes) || isEmpty(dataRes.data) || isEmpty(dataRes.data.bizData)) return [];

	const warehouse = [];

	for (let areaIndex = 0; areaIndex < modelRes.data.areaList.length; areaIndex++) {
		const orgArea = modelRes.data.areaList[areaIndex];

		if (isEmpty(orgArea)) return;

		const area = {
			index: orgArea.index,
			names: {},
			datas: []
		};

		if (!isEmpty(orgArea.name)) {
			area.names = orgArea.name;
		}

		if (!isEmpty(orgArea.sensorList) && !isEmpty(modelRes.data.sensorType)) {
			for (let sensorIndex = 0; sensorIndex < orgArea.sensorList.length; sensorIndex++) {
				const sensor = orgArea.sensorList[sensorIndex];

				if (!isEmpty(sensor)) {
					const typeDesc = getTypeDesc(sensor.type, modelRes.data.sensorType);

					area.datas.push({
						key: sensor.keyName,
						action: typeDesc.valueType === 'level' ? 'level' : 'chart',
						type: sensor.type,
						value: dataRes.data.bizData[sensor.keyName],
						valueType: typeDesc.valueType,
						units: typeDesc.unit,
						names: typeDesc.name,
						levels: typeDesc.valueType === 'level' ? typeDesc.levelList : []
					});
				}
			}
		}

		if (!isEmpty(orgArea.switchList) && !isEmpty(modelRes.data.switchType)) {
			for (let switchIndex = 0; switchIndex < orgArea.switchList.length; switchIndex++) {
				const switchItem = orgArea.switchList[switchIndex];

				if (!isEmpty(switchItem)) {
					const typeDesc = getTypeDesc(switchItem.type, modelRes.data.switchType);

					area.datas.push({
						key: switchItem.keyName,
						action: 'control',
						type: switchItem.type,
						value: dataRes.data.bizData[switchItem.keyName],
						units: typeDesc.unit,
						names: typeDesc.name,
						status: typeDesc.stateList
					});
				}
			}
		}

		warehouse.push(area);
	}

	return warehouse;
};

export { DEFAULT_LANG, getParms, getEnvData, getPressure, getLanguage };
