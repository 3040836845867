import { ReactComponent as IconHome } from './icons/icon_home.svg';
import { ReactComponent as IconDev } from './icons/icon_dev.svg';
import { ReactComponent as IconSetting } from './icons/icon_setting.svg';
import { ReactComponent as IconUpload } from './icons/icon_upload.svg';
import { ReactComponent as IconCardSetting } from './icons/icon_card_setting.svg';
import { ReactComponent as IconId } from './icons/icon_id.svg';
import { ReactComponent as IconDashboard } from './icons/icon_dashboard.svg';

const Res = {
	Icon: {
		Home: IconHome,
		Dashboard: IconDashboard,
		Dev: IconDev,
		Id: IconId,
		Setting: IconSetting,
		Upload: IconUpload,
		Card: {
			Setting: IconCardSetting
		}
	}
};

export default Res;
