import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(XHR).use(LanguageDetector).use(initReactI18next).init({
// i18n.use(XHR).use(initReactI18next).init({
	backend: {
		/* translation file path */
		loadPath: '/i18n/{{ns}}/{{lng}}.json'
	},
	fallbackLng: 'en',
	// debug: true,
	ns: [ 'locales' ],
	defaultNS: 'locales',
	interpolation: {
		escapeValue: false
		// formatSeparator: ','
	},
	react: {
		// wait: true,
		useSuspense: false
	}
});

export default i18n;
