export default function compareVersion(currentVersion,referVersion) {
    let cNum=numVersion(currentVersion);
    let rNum=numVersion(referVersion);
    if(cNum[0]>rNum[0]){
        return true;
    }else {
        if(cNum[0]===rNum[0]){
            if(cNum[1]>rNum[1]){
                return true;
            }else {
                if(cNum[1]===rNum[1]){
                    if(cNum[2]>rNum[2]){
                        return true;
                    }else {
                        if(cNum[2]===rNum[2]){
                            return true;
                        }else {
                            return false
                        }
                    }
                }else {
                    return false;
                }
            }
        }else {
            return false;
        }
    }
}

function numVersion(version) {
    let numArray=[];
    for(let i=0;i<3;i++){
        let point = version.lastIndexOf(".");
        let num = version.substring(point + 1,version.length);   //最后一个.后面的值
        numArray.unshift(Number(num));
        version = version.substring(0,point);   //最后一个.前面的值
        if(version.indexOf(".")===-1){
            numArray.unshift(Number(version));
            break;
        }
    }
    return numArray;
}
