import React, { useEffect, useState } from 'react';
import isEmpty from 'is-empty';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';

import request from 'utils/request';

const fetchTime = 10;

const StatusChartItem = props => {
	const [ data, setData ] = useState({
		charts: []
	});
	const [ isAutoFetch, setIsAutoFetch ] = useState(false);

	const fetchHistoryData = async () => {
		const endDate = moment().toISOString();
		const startDate = moment(endDate).subtract(1, 'hours').toISOString();

		const response = await request.post('laolaoliu/api/v1/get-device-history', {
			recordSnList: props.collectionSnArray,
			queryParam: [ props.data.key ],
			gmtStart: startDate,
			gmtEnd: endDate,
			basePoint: props.point === 'hour' ? props.point : '',
			pageLimit: 10000,
			pageIndex: 1
		});

		if (response.data && response.data.bizCode === 0 && response.data.bizData && response.data.bizData.historyData) {
			response.data.bizData.historyData.forEach(item => {
				item.value = isEmpty(item[props.data.key]) ? 0 : parseInt(item[props.data.key], 10);
				item.time =
					props.point === 'hour'
						? moment.utc(item.gmtHappen).local().format('HH:mm')
						: moment.utc(item.gmtHappen).local().format('HH:mm:ss');
			});

			setData(prevState => {
				return { charts: response.data.bizData.historyData };
			});

			setIsAutoFetch(true);
		}
	};

	const fetchCurrentData = async () => {
		const res = await request.post('laolaoliu/api/v1/get-device-status', {
			controlSn: props.controlSn,
			recordSnList: props.collectionSnArray,
			recordModel: props.model
		});

		if (res.data && res.data.bizCode === 0 && res.data.bizData && res.data.bizData[props.data.key]) {
			const item = {
				value: parseInt(res.data.bizData[props.data.key], 10),
				time:
					props.point === 'hour'
						? moment.utc(res.data.bizData['gmtHappen']).local().format('HH:mm')
						: moment.utc(res.data.bizData['gmtHappen']).local().format('HH:mm:ss')
			};

			setData(prevState => {
				return { charts: [ ...prevState.charts, item ] };
			});
		}
	};

	useEffect(
		() => {
			fetchHistoryData();

			if (isAutoFetch) {
				const interval = setInterval(() => {
					fetchCurrentData();
				}, 1000 * fetchTime);

				return () => {
					clearInterval(interval);
				};
			}
		},
		[ isAutoFetch ]
	);

	return (
		<Box flex='1' p='10px' display='flex' style={{ overflowX: 'auto', overflowY: 'hidden' }}>
			<ResponsiveContainer>
				<LineChart data={data.charts} margin={{ left: -15, right: 25 }}>
					{props.data.type === 'differentialPressure' ? <ReferenceLine y={0} label={'0Pa'} stroke='red' /> : ''}

					<XAxis dataKey='time' tick={false} />

					<YAxis dataKey='value' domain={props.data.type === 'differentialPressure' ? [ -12, 12 ] : [ 0, 'dataMax' ]} />

					<Line type='monotone' isAnimationActive={false} dataKey='value' stroke='#72B8F9' dot={false} strokeWidth={2} />
				</LineChart>
			</ResponsiveContainer>
		</Box>
	);
};

export default withTranslation()(StatusChartItem);
