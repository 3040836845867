import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
	palette: {
		background: {
			default: 'white'
		}
	},
	overrides: {
		MuiOutlinedInput: {
			input: {
				padding: '12px 15px'
			}
		},
		MuiTabs: {
			root: {
				minHeight: '60px'
			}
		},
		MuiButton: {
			label: {
				textTransform: 'none'
			}
		}
	}
});
