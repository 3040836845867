import React, { useEffect, useState } from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Box, Switch, Checkbox, FormControlLabel, TablePagination, InputBase, IconButton, makeStyles, Select, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import request from 'utils/request';
import { getLanguage, DEFAULT_LANG } from 'utils/tools';

const useStyles = makeStyles(theme => ({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center'
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	divider: {
		height: 28,
		margin: 4
	}
}));

const Develop = ({ t }) => {
	const history = useHistory();

	const classes = useStyles();

	const columns = [
		{
			title: t('desktop.lightId.table.id'),
			field: 'serialNum',
			editable: 'never',
			width: 200
		},
		{
			title: t('desktop.lightId.table.name'),
			field: 'nickName',
			editable: 'always',
			width: 150
		},
		{
			title: t('desktop.lightId.table.state'),
			field: 'enable',
			editable: 'never',
			width: 150,
			render: rowData => {
				return (
					<Box>
						<Switch
							onChange={event => {
								rowData.enable = event.target.checked;
								updateLightId(rowData);
							}}
							checked={rowData.enable == 1}
							color='primary'
						/>{' '}
						{t(rowData.enable == 1 ? 'desktop.on' : 'desktop.off')}
					</Box>
				);
			}
		},
		{
			title: t('desktop.lightId.table.permission'),
			field: 'cabinIdList',
			editable: 'never',
			headerStyle: {
				textAlign: 'left'
			},
			cellStyle: {
				textAlign: 'left'
			},
			render: rowData => {
				{
					return (
						<Box>
							{cabins.map((cabin, index) => {
								return (
									<Box key={cabin.id} display='inline-flex' mr={2} alignItems='center'>
										<FormControlLabel
											style={{ marginRight: 0 }}
											value={cabin.id}
											control={
												<Checkbox
													checked={!isEmpty(rowData.cabinIdList) && rowData.cabinIdList.includes(cabin.id)}
													onChange={event => {
														if (event.target.checked) {
															rowData.cabinIdList.push(event.target.value);
														} else {
															const cabinIndex = rowData.cabinIdList.indexOf(event.target.value);

															if (cabinIndex > -1) {
																rowData.cabinIdList.splice(cabinIndex, 1);
															}
														}

														updateLightId(rowData);
													}}
													color='primary'
												/>
											}
											label={cabin.name}
										/>
										<img
											alt='status'
											src={
												cabin.status === 0 ? (
													`${process.env.PUBLIC_URL}/images/ic_online.png`
												) : (
													`${process.env.PUBLIC_URL}/images/ic_offline.png`
												)
											}
											style={{ width: '20px', height: '20px', marginLeft:'5px' }}
										/>
									</Box>
								);
							})}
						</Box>
					);
				}
			}
		}
	];

	const [ lightIds, setLightIds ] = useState([]);
	const [ cabins, setCabins ] = useState([]);
	const [ query, setQuery ] = useState({
		pageIndex: 0,
		pageSize: 5,
		pageTotal: 0,
		pageCount: 0,
		searchKeywords: '',
		searchType: 0
	});

	useEffect(() => {
		fetchCabins();
	}, []);

	useEffect(
		() => {
			fetchLightIds(query.pageIndex, query.pageSize, query.searchType, query.searchKeywords);
		},
		[ query.pageIndex, query.pageSize ]
	);

	const fetchCabins = async () => {
		const response = await request.post('/business-medical/cabin-group/get-cabin-list');

		if (response.data.result === 0 && !isEmpty(response.data.data)) {
			setCabins(response.data.data);
		}
	};

	const fetchLightIds = async (index, size, searchType, searchKeywords) => {
		const response = await request.post('/business-medical/flashkey/get-by-page', {
			pageNum: index,
			pageSize: size,
			nickName: searchType === 1 ? searchKeywords : '',
			serialNum: searchType === 0 ? searchKeywords : ''
		});

		if (response.data.result === 0 && !isEmpty(response.data.data) && !isEmpty(response.data.data.flashKeyList)) {
			setLightIds(response.data.data.flashKeyList);
			setQuery(query => ({
				...query,
				pageCount: response.data.data.pageCount,
				pageTotal: response.data.data.totalCount
			}));
		} else {
			setLightIds([]);
		}
	};

	const fetchQuery = () => {
		if (query.pageIndex === 0) {
			fetchLightIds(query.pageIndex, query.pageSize, query.searchType, query.searchKeywords);
		} else {
			setQuery(query => ({
				...query,
				pageIndex: 0
			}));
		}
	};

	const updateLightId = (newData, _) =>
		new Promise((resolve, reject) => {
			if (isEmpty(newData)) return reject();

			request
				.post('/business-medical/flashkey/update', {
					serialNum: newData.serialNum,
					enable: newData.enable,
					nickName: newData.nickName,
					cabinIdList: newData.cabinIdList
				})
				.then(res => {
					if (res.data.result !== 0) return reject();

					const tmpLightIds = [ ...lightIds ];

					for (let index = 0; index < tmpLightIds.length; index++) {
						if (tmpLightIds[index] && tmpLightIds[index].serialNum === newData.serialNum) {
							tmpLightIds[index] = newData;
							break;
						}
					}

					setLightIds(tmpLightIds);

					resolve();
				})
				.catch(err => {
					reject();
				});
		});

	return (
		<Box mt={3} position='relative'>
			<Box position='absolute' top='-55px' right='10px' bgcolor='white'>
				<InputBase
					className={classes.input}
					placeholder={t('desktop.searchTip')}
					inputProps={{ 'aria-label': 'search' }}
					type='search'
					style={{ marginRight: '10px', width: '200px' }}
					onChange={event => {
						setQuery(query => ({
							...query,
							searchKeywords: event.target.value
						}));

						if (isEmpty(event.target.value) && !isEmpty(query.searchKeywords)) {
							fetchLightIds(query.pageIndex, query.pageSize, query.searchType, '');
						}
					}}
				/>
				<Select
					value={query.searchType}
					onChange={event => {
						setQuery(query => ({
							...query,
							searchType: event.target.value
						}));
					}}>
					<MenuItem value={0}>{t('desktop.lightId.table.id')}</MenuItem>
					<MenuItem value={1}>{t('desktop.lightId.table.name')}</MenuItem>
				</Select>
				<IconButton type='submit' className={classes.iconButton} aria-label='search' onClick={() => fetchQuery()}>
					<SearchIcon />
				</IconButton>
			</Box>
			<MaterialTable
				title={t('desktop.lightId.title')}
				columns={columns}
				data={lightIds}
				editable={{
					onRowUpdate: updateLightId
				}}
				options={{
					search: false,
					sorting: false,
					actionsColumnIndex: -1,
					headerStyle: {
						textAlign: 'center'
					},
					cellStyle: {
						textAlign: 'center'
					}
				}}
				localization={
					getLanguage() === DEFAULT_LANG ? (
						{
							header: { actions: ' ' },
							body: {
								editTooltip: '编辑',
								emptyDataSourceMessage: '没有任何记录',
								editRow: {
									saveTooltip: '保存',
									cancelTooltip: '取消'
								}
							},
							pagination: {
								labelRowsSelect: '条',
								firstAriaLabel: '第一页',
								firstTooltip: '第一页',
								previousAriaLabel: '上一页',
								previousTooltip: '上一页',
								nextAriaLabel: '下一页',
								nextTooltip: '下一页',
								lastAriaLabel: '最后一页',
								lastTooltip: '最后一页',
								labelDisplayedRows: '{from}-{to} 总计 {count}'
							}
						}
					) : (
						{}
					)
				}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPage={query.pageSize}
							// count={query.pageCount * query.pageSize}
							count={query.pageTotal}
							page={query.pageIndex}
							onChangePage={(event, page) => {
								setQuery(query => ({
									...query,
									pageIndex: page
								}));
								props.onChangePage(event, page);
							}}
							onChangeRowsPerPage={event => {
								setQuery(query => ({
									...query,
									pageIndex: 0,
									pageSize: event.target.value
								}));
								props.onChangeRowsPerPage(event);
							}}
						/>
					)
				}}
			/>
		</Box>
	);
};

export default withTranslation()(Develop);
