import axios from 'axios';
import isEmpty from 'is-empty';
import Cookies from 'js-cookie';

import { getLanguage } from './tools';

const KEY_TOKEN = 'token';
const KEY_AGREEMENT = 'agreement';
const BASE_URL = 'https://m.robooot.com:1443/';
// const BASE_URL = 'http://localhost:3089/';
const TIME_OUT = 30 * 1000;
const METHOD_GET = 'get';
const METHOD_PUT = 'put';
const METHOD_POST = 'post';

let token = Cookies.get('COOKIE_PERM');
// token = '1fefbf7fcf6ebd6ffa6addbd1c6a0c1c1a0c0c4a1c0d1a7d3cdd6c7d4a6d7d6a6c7aaa4b7e1e7f0ebd6ffa8d6cad2aaaddaa6d6d0c6c1a6a5a1c6dbd4a2ced0d0a1d0d2c0d';d0a0d0c9d8d0a2c5aed3abddd7d5d2cedbd7c3d4b7e1e7f0ebd6ffa4ccdad9d8d2c2c4c6c0c6dbd1a2a5d2aaa0c9d0d5dba1d1add3d';

export default new class Request {
	constructor() {
		if (!isEmpty(token)) {
			this.setToken(token);
		}

		axios.defaults.baseURL = BASE_URL;
		axios.defaults.timeout = TIME_OUT;
		axios.interceptors.request.use(config => {
			if (this.hasToken()) {
				config.headers.Authorization = `Bearer ${this.getToken()}`;
			}
			return config;
		});
		axios.interceptors.response.use(config => {
			// if (config.data.token) {
			// 	this.setToken(config.data.token);
			// }
			return config;
		});
	}

	getToken = () => {
		return localStorage.getItem(KEY_TOKEN);
	};

	setToken = token => {
		localStorage.setItem(KEY_TOKEN, token);
	};

	hasToken = () => {
		const token = this.getToken();

		return token && !isEmpty(token);
	};

	clearToken = () => {
		localStorage.removeItem(KEY_TOKEN);
	};

	setAgreement = token => {
		localStorage.setItem(KEY_AGREEMENT, token);
	};

	hasAgreement = () => {
		const agreement = localStorage.getItem(KEY_AGREEMENT);

		return agreement && !isEmpty(agreement) && agreement == 1;
	};

	clearAgreement = () => {
		localStorage.removeItem(KEY_AGREEMENT);
	};

	request = (url, params, config) => {
		return new Promise((resolve, reject) => {
			axios({
				method: config.method || 'GET',
				url: url,
				data: params || {},
				headers: config.headers || {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'accept-language': getLanguage() || 'en'
					// 'accept-language': getLanguage() || 'zh-CN'
				}
			})
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	get = (url, params) => {
		return this.request(url, params, { method: METHOD_GET });
	};

	put = (url, params) => {
		return this.request(url, params, { method: METHOD_PUT });
	};

	post = (url, params) => {
		return this.request(url, params, { method: METHOD_POST });
	};

	upload = (url, params) => {
		return this.request(url, params, {
			method: METHOD_POST,
			headers: { 'Access-Control-Allow-Origin': '*', 'content-type': 'multipart/form-data' }
		});
	};

	getFullUrl(path) {
		return isEmpty(path) ? '' : `${BASE_URL}${path}`;
	}
}();
