export function isAndroid() {
    return /(Android)/i.test(navigator.userAgent);
}

export function isIOS() {
    return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
}

export function setupWebViewJavascriptBridge(callback) {
    if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
    }

    if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
    }

    window.WVJBCallbacks = [callback];

    var WVJBIframe = document.createElement('iframe');
    WVJBIframe.style.display = 'none';
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';

    document.documentElement.appendChild(WVJBIframe);
    setTimeout(function() {
        document.documentElement.removeChild(WVJBIframe);
    }, 0);
}

export function setAppInterfaceOrientation(orientationType) {
    if (isAndroid()) {
        let result = window.demo.setRequestedOrientationMode(orientationType);
    } else if (isIOS()) {
        setupWebViewJavascriptBridge(function(bridge) {
            bridge.callHandler(
                'setAppInterfaceOrientation', {
                    orientationType: orientationType
                },
                function responseCallback(responseData) {}
            );
        });
    }
}

export function setWebUnscrollable() {
    if (isAndroid()) {} else if (isIOS()) {
        setupWebViewJavascriptBridge(function(bridge) {
            bridge.callHandler('setWebUnscrollable', function responseCallback(responseData) {});
        });
    }
}

//老版右上角按钮
export function showRightTop(json, title) {
    if (isAndroid()) {
        window.demo.showRightTop(json, title);
    } else if (isIOS()) {
        setupWebViewJavascriptBridge(function(bridge) {
            bridge.callHandler(
                'showRightTop', {
                    json: eval(json),
                    title: title,
                },
                function responseCallback() {},
            );
        });
    }
}

//新版右上角按钮
export function showNavigationRightMoreButton(isShow, title) {
    if (isAndroid()) {
        window.demo.showNavigationRightMoreButton(isShow, title);
    } else if (isIOS()) {
        setupWebViewJavascriptBridge(function(bridge) {
            bridge.callHandler(
                'showNavigationRightMoreButton', {
                    isShow: isShow,
                    title: title,
                },
                function responseCallback() {},
            );
            bridge.registerHandler('navigationRightMoreButtonClicked', function() {
                window.navigationRightMoreButtonClicked()
            });
        });
    }
}

//获取版本号
export function getAppVersion(qwe) {
    if (isAndroid()) {
        let result = window.demo.getAppVersion();
        qwe(result)
    } else if (isIOS()) {
        setupWebViewJavascriptBridge(function(bridge) {
            bridge.callHandler('getAppVersion', {},
                function responseCallback(responseData) {
                    qwe(responseData)
                });
        });
    }
}

export default { setWebUnscrollable, showRightTop, showNavigationRightMoreButton, getAppVersion };