import React, { useState } from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import { Box, Typography, TextField, Button } from '@material-ui/core';

import request from 'utils/request';

const Security = ({ t }) => {
	const [ oldPassword, setOldPasswor ] = useState('');
	const [ newPassword, setNewPasswor ] = useState('');
	const [ repeatPassword, setRepeatPasswor ] = useState('');
	const [ isUpdate, setIsUpdate ] = useState(false);
	const [ tips, setTips ] = useState(' ');

	const updatePassword = async () => {
		if (isEmpty(oldPassword)) {
			return setTips(t('pwd.old'));
		}

		if (isEmpty(newPassword)) {
			return setTips(t('pwd.new'));
		}

		if (isEmpty(repeatPassword) || repeatPassword !== newPassword) {
			return setTips(t('pwd.reapeat'));
		}

		setIsUpdate(true);

		const response = await request.post('/business-medical/user/change-pwd', { password: newPassword });

		if (response.data && response.data.result === 0) {
			setOldPasswor('');
			setNewPasswor('');
			setRepeatPasswor('');
			setTips(t('pwd.success'));
			setIsUpdate(false);
		} else if (response.data && response.data.message) {
			setTips(response.data.message);
			setIsUpdate(false);
		} else {
			setTips(`pwd.fail`);
			setIsUpdate(false);
		}
	};

	return (
		<Box>
			<Typography variant='h5' gutterBottom>
				{t('pwd.title')}
			</Typography>

			<Box mt={2}>
				<TextField
					inputProps={{ 'aria-label': 'naked', style: { textAlign: 'center' } }}
					value={oldPassword}
					onChange={e => {
						setOldPasswor(e.target.value);
					}}
					label={t('pwd.lableOld')}
				/>
			</Box>

			<Box mt={2}>
				<TextField
					inputProps={{ 'aria-label': 'naked', style: { textAlign: 'center' } }}
					value={newPassword}
					onChange={e => {
						setNewPasswor(e.target.value);
					}}
					label={t('pwd.lableNew')}
				/>
			</Box>

			<Box mt={2}>
				<TextField
					inputProps={{ 'aria-label': 'naked', style: { textAlign: 'center' } }}
					value={repeatPassword}
					onChange={e => {
						setRepeatPasswor(e.target.value);
					}}
					label={t('pwd.lableRepeat')}
				/>
			</Box>

			<Box mt={3}>{tips}</Box>

			<Box display='flex' mt={1}>
				<Button variant='contained' disabled={isUpdate} color='primary' onClick={updatePassword}>
					{t('pwd.update')}
				</Button>
			</Box>
		</Box>
	);
};

export default withTranslation()(Security);
