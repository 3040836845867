import React from 'react';
import { Tab, withStyles } from '@material-ui/core';

const DEFAULT_COLOR = '#B7B7B7';
const HIGH_LIGHT_COLOR = '#333333';

export default withStyles(theme => ({
	root: {
		minHeight: '60px',
		textTransform: 'none',
		color: DEFAULT_COLOR,
		'&:hover': {
			color: HIGH_LIGHT_COLOR
		},
		'&:focus': {
			color: HIGH_LIGHT_COLOR
		},
		'&$selected': {
			color: HIGH_LIGHT_COLOR
		}
	},
	selected: {}
}))(props => <Tab {...props} />);
