import React, { useContext } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';

import request from 'utils/request';

const useStyles = makeStyles(theme => ({
	navs: {
		'& a': {
			textDecoration: 'none',
			color: '#3C3C3C'
		},
		'& a.current, & a:hover': {
			color: '#364FCC'
		}
	}
}));

const Topbar = props => {
	const { t } = useTranslation();
	const classes = useStyles();

	const logout = () => {
		request.clearToken();
		request.clearAgreement();
	};

	return (
		<Box minWidth={1000} minHeight={70} display='flex' borderBottom='1px solid #ECECEC' justifyContent='space-between' alignItems='center' bgcolor='white'>
			<Box display='flex' alignItems='center'>
				<Box ml={5} fontSize={24} color='#364FCC' textAlign='center'>
					{t('desktop.title')}
				</Box>
			</Box>

			<Box mr={3} display='flex' className={classes.navs} justifyContent='flex-end' alignItems='center'>
				<a onClick={() => logout()} href={'/desktop'} style={{ cursor: 'pointer' }}>
					{t('desktop.setting.navs.logout')}
				</a>
			</Box>
		</Box>
	);
};

export default withTranslation()(Topbar);
