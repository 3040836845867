import React, { useEffect, useState, useReducer } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import request from 'utils/request';

import Topbar from './Topbar';
import Sidebar from './Sidebar';
import Main from './Main';
import LightId from './LightId';
import Setting from './setting';
import Capsule from './Capsule';

import Breadcrumb from 'components/desktop/Breadcrumb';

const DashboardIndex = props => {
	const [ isInit, setInit ] = useState(true);
	const history = useHistory();

	useEffect(() => {
		if (isInit) {
			fetchUser();
		}
	});

	const fetchUser = () => {};

	return (
		<Box display='flex' flexDirection='column' style={{minHeight:"100vh"}}>
			<Topbar />
			<Box minWidth={1000} display='flex' flex='1'>
				<Sidebar />
				<Box flex='1' p={3}>
					<Breadcrumb />
					<Router>
						<Switch>
							<Route exact path='/desktop/dashboard' render={() => <Redirect to='/desktop/dashboard/main' />} />
							<Route exact path='/desktop/dashboard/main'>
								<Main />
							</Route>
							<Route path='/desktop/dashboard/lightId'>
								<LightId />
							</Route>
							<Route path='/desktop/dashboard/capsule'>
								<Capsule />
							</Route>
							<Route path='/desktop/dashboard/setting'>
								<Setting />
							</Route>
						</Switch>
					</Router>
				</Box>
			</Box>
		</Box>
	);
};

export default DashboardIndex;
