import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';

import request from 'utils/request';
import { getEnvData } from 'utils/tools';

import EnvAreaItem from 'components/EnvAreaItem';

const fetchTime = 3;

const EnvAreaList = props => {
	const [ envData, setEnvData ] = useState([]);

	useEffect(
		() => {
			const interval = setInterval(() => {
				fetchEnvData(props.model, props.controlSn, props.collectionSnArray);
			}, 1000 * fetchTime);

			fetchEnvData(props.model, props.controlSn, props.collectionSnArray);

			return () => {
				clearInterval(interval);
			};
		},
		[ props.model, props.controlSn, props.collectionSnArray ]
	);

	const fetchEnvData = async (model, controlSn, collectionSnArray) => {
		const modelResponse = await request.get(`laolaoliu/static/${model}`);
		const dataResponse = await request.post('laolaoliu/api/v1/get-device-status', {
			recordModel: model,
			controlSn: controlSn,
			recordSnList: collectionSnArray
		});

		setEnvData(getEnvData(modelResponse, dataResponse));
	};

	return (
		<Box style={{ overflowX: 'hidden', overflowY:'scroll' }} hidden={props.tabIndex !== props.index}>
			{envData.map(room => {
				return <EnvAreaItem key={room.names['us-en']} data={room} itemCallback={props.itemCallback} />;
			})}
		</Box>
	);
};

export default EnvAreaList;
