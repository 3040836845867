import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { DEFAULT_LANG, getLanguage } from 'utils/tools';

import Overview from './Overview';
import Chart from './Chart';

const StatusIndex = () => {
	
	document.title = getLanguage() === DEFAULT_LANG ? '火眼智慧舱' : 'Gene capsule';

	return (
		<Router forceRefresh={false}>
			<Switch>
				<Route path='/warehouse/status' exact component={Overview} />
				<Route path='/warehouse/status/chart' component={Chart} />
			</Switch>
		</Router>
	);
};

export default StatusIndex;
