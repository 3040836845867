import React, { Fragment } from 'react';
import isEmpty from 'is-empty';
import { withTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box } from '@material-ui/core';

import { DEFAULT_LANG } from 'utils/tools';
import EnvPointItem from './EnvPointItem';

const SIZE_TITLE = 14;
const SPACE_BORDER = '20px';
const COLOR_GRAY = '#999999';
const DEFAULT_COLOR = '#72B8F9';

const EnvAreaItem = props => {
	const { i18n } = props;

	return (
		<Fragment>
			<Box mt={2} mb={1} pb={2} display='flex' alignItems='center' borderBottom='1px solid #F3F4F5'>
				<Box width='5px' height='12px' mr='15px' bgcolor={props.data.color || DEFAULT_COLOR} />

				<Box flex='1' fontSize={SIZE_TITLE}>
					{isEmpty(props.data.names) ? '' : props.data.names[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']}
				</Box>
				
				<Box mr={SPACE_BORDER}>
					<ArrowForwardIosIcon style={{ color: COLOR_GRAY, fontSize: SIZE_TITLE }} />
				</Box>
			</Box>
			
			<Box width='100vw' px='15px' display='flex' flexWrap='wrap' justifyContent='space-between'>
				{props.data.datas.map(item => {
					return (
						<EnvPointItem
							warehousePartName={
								isEmpty(props.data.names) ? '' : props.data.names[i18n.language === DEFAULT_LANG ? 'zh-cn' : 'us-en']
							}
							key={item.key}
							data={item}
							itemCallback={props.itemCallback}
						/>
					);
				})}
			</Box>
		</Fragment>
	);
};

export default withTranslation()(EnvAreaItem);
