import React, { useState, useEffect } from 'react';
import isEmpty from 'is-empty';
import { Box } from '@material-ui/core';

import request from 'utils/request';

import LogsItem from 'components/LogsItem';

const HistoryList = props => {
	const [ id ] = useState(props.id);
	const [ records, setRecords ] = useState([]);

	useEffect(
		() => {
			fetchLogs(id);
		},
		[ id ]
	);

	const fetchLogs = async cabinId => {
		const response = await request.post('business-medical/log/get-by-cabin-id', { id: cabinId });

		if (response.data && response.data.result === 0 && !isEmpty(response.data.data) && !isEmpty(response.data.data.list)) {
			setRecords(response.data.data.list);
		}
	};

	return (
		<Box flex='1' style={{ overflow: 'auto' }} hidden={props.tabIndex !== props.index}>
			{records.map((record, index) => {
				return <LogsItem key={index} data={record} />;
			})}
		</Box>
	);
};

export default HistoryList;
