import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { DEFAULT_LANG, getLanguage } from 'utils/tools';

import List from './List';
import Detail from './Detail';

const HistoryIndex = () => {
	document.title = getLanguage() === DEFAULT_LANG ? '操作记录' : 'Op Log';

	return (
		<BrowserRouter>
			<Switch>
				<Route path='/warehouse/history' exact component={List} />
				<Route path='/warehouse/history/detail' component={Detail} />
			</Switch>
		</BrowserRouter>
	);
};

export default HistoryIndex;
