import React from 'react';
import isEmpty from 'is-empty';
import { createStyles, makeStyles, Box } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Login from './Login';
import DashboardIndex from './dashboard';

import request from 'utils/request';

const AppStyle = makeStyles(theme =>
	createStyles({
		app: {
			background: '#f5f6fa'
		}
	})
);

const DesktopIndex = () => {
	const classes = AppStyle();
	
	return (
		<div className={classes.app}>
			<Router>
				<Switch>
					<Route exact path='/desktop' render={() => <Redirect to='/desktop/dashboard' />} />
					<Route path='/desktop/dashboard'>
						{request.hasToken() && request.hasAgreement() ? <DashboardIndex /> : <Redirect to='/desktop/login' />}
					</Route>
					<Route path='/desktop/login'>
						{request.hasToken() && request.hasAgreement() ? <Redirect to='/desktop/dashboard' /> : <Login />}
					</Route>
				</Switch>
			</Router>
		</div>
	);
};

export default DesktopIndex;
